import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Staffnavbar from "./Staffnavbar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Staffcustomer = () => {
  const { apipath, staffLogout } = useAuth();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const stafftoken = localStorage.getItem('stafftoken');
  const [loading, setLoading] = useState(true);
  const [dashData, setdashData] = useState();
  const [customerData, setcustomerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState('');

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilters(value);
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...customerData];
    const filterValue = filters.toLowerCase(); if (filterValue) {
      filteredItems = filteredItems.filter(item => {
        return Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(filterValue)
        );
      });
    }
    setFilteredData(filteredItems);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'staffcustomer',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + stafftoken
            }
          }
        );
        setdashData(response.data);
        setcustomerData(response.data.customers);
        setFilteredData(response.data.customers);
        if (response.data.status === "false") {
          iziToast.error({
            message: response.data.message,
            position: "topCenter"
          });
          // localStorage.removeItem('stafftoken');
          // navigate('/login');
        }
      }
      catch (error) {
        console.error('Error fetching dashboard data:', error);
        if (localStorage.getItem('stafftoken')) {
          localStorage.removeItem('stafftoken');
        }
        navigate('/login');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);
  console.log(dashData);


  if (loading) {
    return <div>Loading...</div>;
  }

  const scolumns = [
    { name: 'ID', selector: row => row.id, sortable: true, width: '130px' },
    { name: 'Name', selector: row => row.name, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true, width: '250px' },
    { name: 'Phone', selector: row => row.phone, sortable: true },
    { name: 'Service', selector: row => row.service, sortable: true },

    // Add more columns as needed
  ];
  // if (dashData.customers.length > 0) {
  //   var rowdata = dashData.customers;
  //   var rows = rowdata.map((customer) => {
  //     return (
  //       <>
  //       <tr>
  //     <td>{customer.id}</td>
  //     <td>{customer.name}</td>
  //     <td>{customer.email}</td>
  //     <td>{customer.phone}</td>
  //     <td>{customer.service}</td>
  //     <td>{customer.payment_status}</td>
  //     <td>{customer.documents}</td>
  //   </tr>
  //   </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No customers.";
  // }
  return (
    <>
      <Staffnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/staffcustomer`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/staffchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/staffticket`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/stafftask`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link>
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={staffLogout}
                  to={`/customer-profile/`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">
          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>My Customers</h5>
                <div>
                  <div className="row m-0" id="table_detail">
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Search by name"
                        value={filters.order_name}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-2 p-1">
                      <button onClick={applyFilters}>Search</button>
                    </div>
                  </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>Customer ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Company Name</th>
                      <th>Service</th>
                      <th>Payment Status</th>
                      <th>Documents</th>
                    </tr>
                    {rows}
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Staffcustomer