import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useParams, useNavigate } from "react-router-dom";
import Staffnavbar from "./Staffnavbar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Stafftask = () => {
  const navigate = useNavigate();
  const { apipath, staffLogout } = useAuth();
  const [show, setShow] = useState(true);
  const stafftoken = localStorage.getItem('stafftoken');
  const { customerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [orderData, setorderData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState('');

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilters(value);
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...orderData];
    const filterValue = filters.toLowerCase(); if (filterValue) {
      filteredItems = filteredItems.filter(item => {
        return Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(filterValue)
        );
      });
    }
    setFilteredData(filteredItems);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'stafftasks',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + stafftoken
            }
          }
        );
        setorderData(response.data.orders);
        setFilteredData(response.data.orders);
        if (response.data.status === "false") {
          localStorage.removeItem('stafftoken');
          navigate('/adminlogin');
        }
      }
      catch (error) {
        console.error('Error fetching orders data:', error);
        if (localStorage.getItem('stafftoken')) {
          localStorage.removeItem('stafftoken');
        }
        navigate('/stafflogin');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);

  const [statusData, setstatusData] = useState({
    status: '',
    order_id: '',
  });
  const handleStatus = (e) => {
    var oid = e.target.getAttribute('data-orderid');
    setstatusData({ ...statusData, ['status']: e.target.value, ['order_id']: oid });
  }
  useEffect(() => {
    if (statusData.status !== '') {
      axios.post(apipath + 'update_order_status', statusData,
        {
          headers: {
            Authorization: 'Bearer ' + stafftoken
          }
        })
        .then((response) => {
          var data = response.data;
          if (data.status === "false") {
            iziToast.error({
              message: data.message,
              position: "topCenter"
            });
          }
          else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
          }
        })
        .catch((error) => {
          iziToast.error({
            message: error,
            position: "topCenter"
          });
        });
    }
  }, [statusData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  localStorage.setItem('taskData', JSON.stringify(orderData));

  const scolumns = [
    { name: 'Task ID', selector: row => row.order_id, sortable: true, width: '130px' },
    { name: 'Task Name', selector: row => row.order_name, sortable: true, width: '250px' },
    { name: 'Price', selector: row => row.price, sortable: true },
    { name: 'Timeline', selector: row => row.sla, sortable: true },
    { name: 'Category', selector: row => row.category, sortable: true, width: '200px' },
    {
      name: 'Task Status', selector: row => (
        <>
          <select class="status_select" onChange={handleStatus} data-orderid={row.id}>
            <option selected={row.status === "Pending"} value={'Pending'}>Pending</option>
            {row.order_process.map((procs) => {
              return (
                <>
                  <option selected={row.status === procs} value={procs}>{procs}</option>
                </>
              );
            })
            }
          </select></>
      ), sortable: true, width: '200px'
    },
    // { name: 'Documents Upload', selector: row => "Pending", sortable: true },
    {
      name: 'Action', cell: row => (
        <Link to={`/stafftaskdetail/${row.id}`}><i class="bi bi-eye viewbtn"></i></Link>
      )
    },
    // Add more columns as needed
  ];

  // if (orderData.orders.length > 0) {
  //   var rowdata = orderData.orders;
  //   var selprocess = "";
  //   var rows = rowdata.map((ordr) => {

  //     var processdata = ordr.order_process;
  //     if (processdata.length > 0) {
  //       selprocess = processdata.map((procs) => {
  //         return (
  //           <>
  //             <option selected={ordr.status === procs} value={procs}>{procs}</option>
  //           </>
  //         );
  //       });
  //     }
  //     return (
  //       <>
  //         <tr>
  //           <td>{ordr.order_id}</td>
  //           <td>{ordr.order_name}</td>
  //           <td>{ordr.price}</td>
  //           <td>{ordr.sla} days</td>
  //           <td>{ordr.category}</td>
  //           <td>
  //             <select class="status_select" onChange={handleStatus} data-orderid={ordr.id}>
  //               <option selected={ordr.status === "Pending"} value={'Pending'}>Pending</option>
  //               {selprocess}
  //             </select>
  //           </td>
  //           <td></td>
  //           <td>
  //             <Link to={`/stafftaskdetail/${ordr.id}`}><i class="bi bi-eye viewbtn"></i></Link> &nbsp;
  //             {/* <i class="bi bi-trash3"></i> &nbsp; */}
  //             {/* <i class="bi bi-download"></i> &nbsp; */}
  //           </td>
  //         </tr>
  //       </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No Tasks.";
  // }
  return (
    <>
      <Staffnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/staffcustomer`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/staffchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/staffticket`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/stafftask`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link>
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={staffLogout}
                  to={`/customer-profile/`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">
          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <div class="row justify-content-between">
                  <div class="col"><h5>Tasks</h5></div>
                  <div class="col d-flex justify-content-end">
                    <Link to={`/staffaddorder`}>
                      <button className="btn btn-sm btn-primary shadow-none"><i class="bi bi-plus"></i> Add New</button>
                    </Link>
                  </div>
                </div>
                <div className="row m-0" id="table_detail">
                  <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Search"
                      value={filters.order_name}
                      onChange={handleFilterChange}
                    />
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12 mb-2 p-1">
                    <button onClick={applyFilters}>Search</button>
                  </div>
                </div>
                <div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>Task ID</th>
                      <th>Task Name</th>
                      <th>Price</th>
                      <th>Timeline</th>
                      <th>Category</th>
                      <th>Task Status</th>
                      <th>Documents Upload</th>
                      <th>Action</th>
                    </tr>
                    {rows}
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Stafftask