import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

const eko = new Echo({
    broadcaster: 'pusher',
    key: '4543e6799165c00a934e',
    cluster: 'ap2',
    forceTLS: true
});

export default eko;