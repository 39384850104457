import React, { useState, useEffect } from "react";
import iziToast from "izitoast";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
const Orderprogress = () => {
  const [show, setShow] = useState(true);
  const { logout, filepath, apipath } = useAuth();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();

  var [uploadData, setuploadData] = useState({
    order_id: id,
    service_id: '',
    doc_id: '',
    field_value: '',
    file: '',
  });

  const handleFileChange = async (e) => {
    setuploadData({
      ...uploadData,
      ['file']: e.target.files[0],
      ['service_id']: e.target.getAttribute('data-servid'),
      ['doc_id']: e.target.getAttribute('data-docid'),
      ['field_value']: '',
    });
  };
  const handleTextChange = async (e) => {
    setuploadData({
      ...uploadData,
      ['field_value']: e.target.value,
      ['service_id']: e.target.getAttribute('data-servid'),
      ['doc_id']: e.target.getAttribute('data-docid'),
      ['file']: '',
    });
    console.log(e.target.getAttribute('data-servid'));
  };
  const checkAuthentication = async () => {
    try {
      const response = await axios.post(
        apipath + 'order_detail/' + id,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      );
      setOrder(response.data.order);
      console.log(response.data);
      if (response.data.status === "false" && response.data.message === "Unauthentic access") {
        localStorage.removeItem('token');
        navigate('/login');
      }
      if (response.data.status === "false" && response.data.message !== "Unauthentic access") {
        iziToast.error({
          message: response.data.message,
          position: "topCenter"
        });
      }
    }
    catch (error) {
      console.error('Error fetching orders data:', error);
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }
      navigate('/login');

    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleFileSubmit = async (e) => {
    e.preventDefault();
    console.log(uploadData);
    try {
      const response = await axios.post(
        apipath + 'upload_document',
        uploadData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      console.log(response.data);
      if (response.data.status === "false" && response.data.message === "Unauthentic access") {
        localStorage.removeItem('token');
        navigate('/login');
      }
      if (response.data.status === "false" && response.data.message !== "Unauthentic access") {
        iziToast.error({
          message: response.data.message,
          position: "topCenter"
        });
      }
      if (response.data.status === "true") {
        iziToast.success({
          message: response.data.message,
          position: "topCenter"
        });
        checkAuthentication();
      }
    } catch (error) {
      console.error('Error fetching orders data:', error);
      // if (localStorage.getItem('token')) {
      //   localStorage.removeItem('token');
      // }
      // navigate('/login');
    }
  }
  const handleTextSubmit = async (e) => {
    e.preventDefault();
    // console.log(uploadData);
    try {
      const response = await axios.post(
        apipath + 'upload_document',
        uploadData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      console.log(response.data);
      if (response.data.status === "false" && response.data.message === "Unauthentic access") {
        localStorage.removeItem('token');
        navigate('/login');
      }
      if (response.data.status === "false" && response.data.message !== "Unauthentic access") {
        iziToast.error({
          message: response.data.message,
          position: "topCenter"
        });
      }
      if (response.data.status === "true") {
        iziToast.success({
          message: response.data.message,
          position: "topCenter"
        });
        checkAuthentication();
      }
    } catch (error) {
      console.error('Error fetching orders data:', error);
      // if (localStorage.getItem('token')) {
      //   localStorage.removeItem('token');
      // }
      // navigate('/login');
    }
  }
  const handleReupload = async (e, id) => {
    e.preventDefault();
    e.target.disabled = true;
    console.log(uploadData);
    try {
      const response = await axios.post(
        apipath + 'delete_document',
        {'id':id},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          }
        }
      );
      console.log(response.data);
      if (response.data.status === "false" && response.data.message === "Unauthentic access") {
        localStorage.removeItem('token');
        navigate('/login');
      }
      if (response.data.status === "false" && response.data.message !== "Unauthentic access") {
        iziToast.error({
          message: response.data.message,
          position: "topCenter"
        });
      }
      if (response.data.status === "true") {
        iziToast.success({
          message: response.data.message,
          position: "topCenter"
        });
        checkAuthentication();
      }
      e.target.disabled = false;
    } catch (error) {
      console.error('Error fetching orders data:', error);
      // if (localStorage.getItem('token')) {
      //   localStorage.removeItem('token');
      // }
      // navigate('/login');
    }
  }

  if (loading) {
    return <div>Loading...</div>;
  }


  if (order.documents.length > 0) {
    var docData = order.documents;
    var docs = docData.map((doc) => {
      var field = '';
      if (doc.field_type === "file") {
        if (doc.status == "Uploaded") {
          var file = filepath + "user" + order.user_id + "/" + doc.field_value;
          field = (
            <>
              {(doc.field_value !== "") ? <><a href={file} download ><button>Download</button></a><button onClick={(e)=>{handleReupload(e, doc.upload_id)}}>Reupload</button></> : ""}
            </>
          );
        }
        else {
          field = (
            <>
              <form onSubmit={handleFileSubmit}>
                <div class="filebutton_box">
                  <input class="fileupload" accept=".pdf,.jpg,.png,.jpeg" type="file" required onChange={handleFileChange} data-servid={order.service_id} data-docid={doc.id} id={"field" + doc.id} name="field_value" />
                  <button>Upload</button>
                </div>
              </form>
            </>
          );
        }
      }
      if (doc.field_type === "text") {
        field = (
          <>
            <form onSubmit={handleTextSubmit}>
              <input required type="text" placeholder={doc.doc_name} onChange={handleTextChange} data-servid={order.service_id} data-docid={doc.id} id={"field" + doc.id} name="field_value" />
              <button>Upload</button>
            </form>
          </>
        );
      }
      return (
        <>

          <div className="order-innerbox">
            <div className="order-status-detail">
              <div className="col-9 me-4">
                <h4>{(doc.field_type === "file") ? "Document Upload" : "Text Upload"} {(doc.required === "true") ? "*" : ""}</h4>
                <h6>
                  {doc.doc_name ?? ""}
                </h6>
                {(doc.field_type === "text" && doc.field_value) ? <h6>Text Uploaded : {doc.field_value}</h6> : ""}
              </div>
              <div className="pending col-3">
                <i className="bi bi-check-circle-fill"></i>
                <h4 className="pt-1">{doc.status}</h4>
              </div>
            </div>
            <div className="pending-box">
              <small>{doc.upload_date}</small>
              {field}
            </div>
          </div>
          <hr />
        </>
      );
    });
  }
  else {
    docs = "";
  }
  if (order.order_process.length > 0) {
    var order_status = order.status;
    var processData = order.order_process;
    var done = "true";
    var processes = processData.map((process) => {
      if (order_status === process || (done === "true" && order_status !== "Pending")) {
        var clas = "StepProgress-item is-done";
        if (order_status === process) {
          done = "false";
        }
      }
      else {
        clas = "StepProgress-item current";
      }
      return (
        <>
          <li className={clas}>
            <strong>{process}</strong>
          </li>
        </>
      );
    });
  }
  else {
    processes = "";
  }

  return (
    <>
      <Dashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <Sidebar activePage={'myorders'} />

        <div className="home-container">
          <div className="container pt-4 pb-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="order-status">
                  <h5>
                    {" "}
                    Service - <b>{order.order_name}</b>{" "}
                  </h5>
                  <hr />
                  <div className="order-innerbox">
                    <div className="order-status-detail">
                      <div className="col-9 me-4">
                        <h4>Payment Completed</h4>
                        <h6>
                          Registration fee for {order.order_name} has been
                          paid
                        </h6>
                      </div>
                      <div className="col-3">
                        <i className="bi bi-check-circle-fill"></i>
                        <h4 className="pt-1">Confirmed</h4>
                      </div>
                    </div>
                    <small>{order.fulldate}</small>
                  </div>
                  <hr />
                  <div className="order-innerbox">
                    <div className="order-status-detail">
                      <div className="col-9 me-4">
                        <h4>Account Logged in</h4>
                        <h6>
                          Registration fee for {order.order_name} has been
                          paid
                        </h6>
                      </div>
                      <div className="col-3">
                        <i className="bi bi-check-circle-fill"></i>
                        <h4 className="pt-1">Confirmed</h4>
                      </div>
                    </div>
                    <small>{order.fulldate}</small>
                  </div>
                  <hr />
                  {docs}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="order-status">
                  <div className="chat-head">
                    <h5>Order Status</h5>
                    <img src="/images/chat.png" style={{ 'cursor': 'pointer' }} width={40} height={40} alt="" onClick={() => { window.location.href = `/chat/${order.id}` }} />
                  </div>
                  <hr />
                  <div className="wrapper">
                    <ul className="StepProgress">
                      <li className="StepProgress-item is-done">
                        <strong>Logged In</strong>
                      </li>
                      <li className="StepProgress-item is-done">
                        <strong>Payment Completed</strong>
                        Registration fee for {order.order_name} has been{" "} paid
                      </li>
                      {processes}
                      {/* <li className="StepProgress-item current">
                        <strong>Upload Documents</strong>
                        Please upload the pending documents required for this{" "}
                        <br />
                        Service
                      </li>
                      <li className="StepProgress-item current">
                        <strong>Documents Collected</strong>
                      </li>
                      <li className="StepProgress-item current">
                        <strong>Document Validation</strong>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Orderprogress;
