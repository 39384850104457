import React, { useState, useEffect } from 'react';
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import Footer from './footer';
import { useAuth } from '../AuthContext';
const Payment = () => {
  const { apipath, adminLogout } = useAuth();
  const token = localStorage.getItem('token');
  const navigate = useNavigate;
  const { id } = useParams();
  const sdata = localStorage.getItem('serviceData');
  const servicedata = JSON.parse(sdata);

  var service = {
    id: "",
    name: "",
    category: "",
    price: "",
  }
  for (let i = 0; i < servicedata.length; i++) {
    if (servicedata[i].id == id) {
      service = servicedata[i];
    }
  }

  const checkid = async () => {
    if (id === "") {
      iziToast.error({
        message: "Service not found.",
        position: "topCenter"
      });
      navigate('/services');
    }
  }
  const checkservice = async () => {
    if (service.id === "") {
      iziToast.error({
        message: "Service not found.",
        position: "topCenter"
      });
      navigate('/services');
    }
  }
  useEffect(() => {
    checkid();
    checkservice();
  }, []);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    payment_id: '',
    service_id: service.id,
  });
  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  var samount = parseInt(service.price) + (parseInt(service.price) * .18);
  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post(apipath + 'check_validation', userData,
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(async (response) => {
        var data = response.data;
        if (data.status === "false") {
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
        else if (data.status === 'true') {
          var options = {
            // "key": "rzp_test_uuigTJodTkccX1",
            "key": "rzp_live_12AxfcXIyG1uHV",
            "amount": samount * 100,
            // "amount": 1*100,
            "name": "Taxaj", //your business name
            "description": "Test Transaction",
            "image": "https://example.com/your_logo",
            "handler": function (response) {
              console.log(response);
              userData.payment_id = response.razorpay_payment_id;
              axios.post(apipath + 'place_order', userData,
                {
                  headers: {
                    Authorization: 'Bearer ' + token
                  }
                }).then((response) => {
                  var data = response.data;
                  if (data.status === "false") {
                    iziToast.error({
                      message: data.message,
                      position: "topCenter"
                    });
                  }
                  else if (data.status === "true") {
                    localStorage.setItem('thankyou', service.price);
                    iziToast.success({
                      message: data.message,
                      position: "topCenter"
                    });
                    window.location.href = "/myorders";
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            },
            "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
              "name": userData.name, //your customer's name
              "email": userData.email,
              "contact": userData.phone  //Provide the customer's phone number for better conversion rates 
            },
            "theme": {
              "color": "#3399cc"
            }
          };
          var rzp = new window.Razorpay(options);
          rzp.open();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <>
      <Dashnavbar />
      <div className="container-fluid pt-5 mt-5 pb-3">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="payment-box">
              <div className="pay-icon pt-4">
                <img src="/images/pay-icon.png" alt="" />
              </div>
              <div className="card-box pt-4">
                <img src="/images/master.png" width={50} height={50} alt="" />
                <img src="/images/visa.png" width={51} height={16} alt="" />
                <img src="/images/express.png" width={40} height={27} alt="" />
              </div>
              <form className="row payment-form" onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="" className='mb-2'>Name</label>
                  <input type="text" required name='name' className="form-control" onChange={handleChange} placeholder='Name' />
                  <span toggle="#password-field" className="bi bi-person new-field-icon toggle-password"></span>
                </div>
                <div className="mb-4">
                  <label htmlFor="" className='mb-2'>Email</label>
                  <input type="email" required name='email' className="form-control" onChange={handleChange} placeholder='Email' />
                </div>
                <div className="mb-4">
                  <label htmlFor="" className='mb-2'>Phone</label>
                  <input type="text" pattern='\d*' required name='phone' className="form-control" onChange={handleChange} placeholder='Phone' />

                </div>
                <div className="mb-4">
                  <label htmlFor="" className='mb-2'>GST Number</label>
                  <input type="text" maxLength="15" minLength="15" name='gstno' className="form-control" onChange={handleChange} placeholder='GST Number' />

                </div>
                <button class="register-btn mb-4" type='submit'>
                  <span> Pay Now (Rs.{samount})<span className="p-0" style={{'font-size':'10px'}}>GST Inc.</span></span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Payment