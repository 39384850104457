import React, { useState } from "react";
import axios from 'axios';
import iziToast from 'izitoast';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../AuthContext';
const Register = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('userData', JSON.stringify(userData));
    axios.post(apipath+'checkregister', userData)
      .then((response) => {
        var data = response.data;
        if(data.status === "false"){
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
        else if(data.status === "true"){
          iziToast.success({
            message: data.message,
            position: "topCenter"
          });
          navigate('/otp');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  var viewPassword = () => {
    var password = document.getElementById('password');
    if(password.getAttribute('type') === "password"){
        password.setAttribute('type','text');
    }
    else{
        password.setAttribute('type','password');
    }
}
  return (
    <>
      <div className="container-fluid register-container">
        <div className="row main-register-row">
          <div className="col-lg-4">
            <div className="register-box-left">
              <img src="images/reg-icon.png" alt="" />
            </div>
          </div>
          <div className="col-lg-8 register-column">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="register-box-right">
                  <h4>Register Your Account</h4>
                  <h6 className="pt-3 pb-4">
                    {" "}
                    Welcome to <span>TaxAJ</span>{" "}
                  </h6>

                  <form className="register-form" onSubmit={handleSubmit}>
                    <div className="mb-5">
                      <input type="text" name="name" required className="form-control" onChange={handleChange} placeholder="Full Name" />
                    </div>
                    <div className="mb-5">
                      <input type="email" name="email" required className="form-control" onChange={handleChange} placeholder="Email Address" />
                    </div>
                    <div className="mb-5">
                      <input type="text" name="phone" required className="form-control" onChange={handleChange} placeholder="Phone Number" />
                    </div>
                    <div className="mb-5">
                      <input type="password" name="password" id="password" required className="form-control" onChange={handleChange} placeholder="Password" />
                      <span toggle="#password-field" className="bi bi-eye-fill field-icon toggle-password" onClick={viewPassword}></span>
                    </div>
                    <button class="register-btn mb-4">
                      <span>Create Account</span>
                    </button>
                    <small>Already have an account? <Link to="/login" >Log in</Link> </small>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
