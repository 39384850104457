import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import eko from '../eko';
import { useAuth } from '../AuthContext';

const Staffnavbar = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  const staffid = localStorage.getItem('staffid');
  const stafftoken = localStorage.getItem('stafftoken');
  const [unread, setunread] = useState(0);
  const [myNotifications, setNotifications] = useState({
    notifications: []
  });

  const readNotification = async () => {
    try {
      axios.post(apipath + 'readnotification',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + stafftoken
          }
        })
        .then(response => {
          setunread(response.data.readcount);
          // console.log(response.data);
          if (response.data.status === "false") {
            localStorage.removeItem('stafftoken');
            navigate('/stafflogin');
          }
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
    }
    catch (error) {
      console.error('Error fetching dashboard data:', error);
      if (localStorage.getItem('stafftoken')) {
        localStorage.removeItem('stafftoken');
      }
      navigate('/stafflogin');

    }
  }
  const checkAuthentication = async () => {
    try {
      axios.post(apipath + 'getnotification',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + stafftoken
          }
        })
        .then(response => {
          setNotifications(response.data);
          setunread(response.data.readcount);
          // console.log(response.data);
          if (response.data.status === "false") {
            localStorage.removeItem('stafftoken');
            navigate('/stafflogin');
          }
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
    }
    catch (error) {
      console.error('Error fetching dashboard data:', error);
      if (localStorage.getItem('stafftoken')) {
        localStorage.removeItem('stafftoken');
      }
      navigate('/stafflogin');

    }
  }
  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    const handleNewMessage = (data) => {
      // console.log(data);
      setNotifications(prevState => {
        return {
          ...prevState,
          notifications: [...prevState.notifications, data.message]
        };
      });
    };

    const channel = eko.channel('notification.' + staffid);
    channel.listen('.notification.sent', handleNewMessage);

    // Cleanup function to unsubscribe from the channel
    return () => {
      channel.stopListening('.notification.sent', handleNewMessage);
    };
  }, [unread]);


  if (myNotifications.notifications.length > 0) {
    // console.log("find some messages");
    var mymsgs = myNotifications.notifications;
    // console.log(mymsgs);
    var showmsg = mymsgs.map((msg) => {
      var linkmsg = '';
      if (msg.title === 'New Message') {
        if (msg.order_id) {
          linkmsg = '/staffchat/' + msg.order_id;
        }
        else {
          linkmsg = '/staffchat';
        }

      }
      if (msg.title === 'New Registration') {
        linkmsg = '/staffcustomer';

      }
      if (msg.title === 'Task Status Updated' || msg.title === 'New Task Assigned' || msg.title === 'Text Uploaded') {
        if (msg.order_id) {
          linkmsg = '/stafftaskdetail/' + msg.order_id;
        }
      }
      if (msg.title === 'Ticket Reply' || msg.title === 'Ticket Raised' || msg.title === 'New Ticket Assigned' || msg.title === 'Ticket Status Updated') {
        if (msg.ticket_id) {
          linkmsg = '/staffticketview/' + msg.ticket_id;
        }
      }
      return (
        <>
          <Link to={linkmsg}>
            <li className="my-1 bg-light">
              <div class="dropdown-item notification" style={{ 'width': '100%', 'wordWrap': 'break-word', 'whiteSpace': 'normal' }}>
                <h6 className="m-0">{msg.title}</h6>
                <span className="m-0" style={{ 'wordWrap': 'break-word', 'width': '100%' }}>{msg.detail}</span>
              </div>
            </li>
          </Link>
        </>
      );
    });
  }
  else {
    showmsg = "";
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="inner-nav">
            <div className='nav-imgbox'>
              <Link to={'/staffcustomers'}>
                <img src="/logo.png" alt="" />
              </Link>
            </div>
            <div className="notify-navbox admin-staffnav">
              <div className='me-3 pt-2'>
                <button className="border-0 bg-white" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { readNotification() }}>
                  <i className="fa-regular fa-bell me-2">
                    <sup className="bg-danger p-1 text-white rounded"><small>{unread}</small></sup>
                  </i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ 'max-height': '300px', 'overflow': 'auto', 'width': '200px' }}>
                  {showmsg}
                </ul>
                {/* <i class="bi bi-chat-square-dots"></i> */}
              </div>
              <div className='vertical-line me-3'>
              </div>
              <div class="nav-menu d-flex align-items-center">
                <h4 class="me-2 pt-2">Hello! <span>Staff</span></h4>
                <img src="/images/profile.png" width={50} height={50} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Staffnavbar