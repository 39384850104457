import React, { useState, useEffect } from "react";
import iziToast from "izitoast";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
const Adminorderdetail = () => {
    const { apipath, adminLogout } = useAuth();
    const [show, setShow] = useState(true);
    const { customerId } = useParams();
    const { id } = useParams();
    const navigate = useNavigate();
    const admintoken = localStorage.getItem('admintoken');
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState();
  
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'adminorder_detail/' + id,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + admintoken
            }
          }
        );
        setOrder(response.data.order);
        console.log(response.data);
        if (response.data.status === "false" && response.data.message === "Unauthentic access") {
          localStorage.removeItem('token');
          navigate('/login');
        }
        if (response.data.status === "false" && response.data.message !== "Unauthentic access") {
          iziToast.error({
            message: response.data.message,
            position: "topCenter"
          });
        }
      }
      catch (error) {
        console.error('Error fetching orders data:', error);
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
        navigate('/login');
  
      } finally {
        setLoading(false);
      }
    }
    useEffect(() => {
      checkAuthentication();
    }, []);
  
    if (loading) {
      return <div>Loading...</div>;
    }
    return (
        <>
            <Admindashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <aside className={`sidebar ${show ? "show1" : null}`}>
                    <nav className="nav1">
                        <div>
                            <div className="nav-list">
                                <Link
                                    to={`/admindashboard`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-grid-1x2"></i>
                                    <span className="nav-link-name">Dashboard</span>
                                </Link>
                                <Link
                                    to={`/admincustomers`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                    <span className="nav-link-name">Customers</span>
                                </Link>
                                <Link
                                    to={`/adminstaff`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Staff</span>
                                </Link>
                                <Link
                                    to={`/adminorders`}
                                    className="nav-linksidebar active-bar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Orders</span>
                                </Link>
                                <Link
                                    to={`/adminchat`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-chat-left-text"></i>
                                    <span className="nav-link-name">Chats</span>
                                </Link>
                                <Link
                                    to={`/adminticket`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-ticket-detailed"></i>
                                    <span className="nav-link-name">Tickets</span>
                                </Link>
                                <Link
                                    to={`/adminservice`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Services</span>
                                </Link>
                                {/* <Link
                                    to={`/admintask`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Tasks</span>
                                </Link> */}
                                <hr />
                                {/* <Link
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-webcam"></i>
                                    <span className="nav-link-name">Profile</span>
                                </Link> */}
                                <Link onClick={adminLogout}
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-gear"></i>
                                    <span className="nav-link-name">Logout</span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </aside>

                <div className="home-container">

                    <div className="container pt-4">
                        <div className="row">
                            <div class="col-lg-12 table-column">
                                <h2>Order Detail</h2>
                                <h5>Service : {order.order_name}</h5>
                                <h5>Payment ID : {order.payment_id}</h5>
                                <p><strong>User Details :-</strong><br></br>
                                    Name : {order.user_name}<br></br>
                                    Email : {order.user_email}<br></br>
                                    Phone : {order.user_phone}<br></br>
                                    GST Number : {order.gst_number}<br></br>
                                </p>
                                <p>
                                    Category : {order.category}<br></br><br></br>
                                    Description : <br></br>{order.details}</p>
                                <p>Documents : <br></br><ul>{order.documents.map((doc) => { return (<><li>{doc.doc_name}</li> </>); })}</ul></p>
                                <p>Timeline : {order.sla}</p>
                                <p>Order Price : <strong>Rs.{order.price}/-</strong></p>
                                <p>Order Date : {order.date}</p>
                                <p>Order Status : {order.status}</p>
                                <Link to={`/adminorderprogress/${order.id}`}><button class="btn btn-primary">Order Progress</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Adminorderdetail