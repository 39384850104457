import React, { useState, useEffect } from "react";
import iziToast from "izitoast";
import { Link, useParams, useNavigate } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
const Adminservicedetails = () => {
    const { apipath, adminLogout } = useAuth();
    const [show, setShow] = useState(true);
    const { customerId } = useParams();
    const { id } = useParams();
    const navigate = useNavigate();
    const sdata = localStorage.getItem('serviceData');
    const servicedata = JSON.parse(sdata);

    var service = {
        id: "",
        name: "",
    }
    for (let i = 0; i < servicedata.length; i++) {
        if (servicedata[i].id == id) {
            service = servicedata[i];
            console.log(service);
        }
    }
    const [servData, setservData] = useState({
        id: service.id,
        name: service.name,
        email: service.email,
        phone: service.phone,
        department: service.department,
        password: service.password,
    });

    const checkservice = async () => {
        if (service.id === "") {
            iziToast.error({
                message: "Service not found.",
                position: "topCenter"
            });
            navigate('/adminservices');
        }
    }
    useEffect(() => {
        checkservice();
    }, []);
    return (
        <>
            <Admindashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <aside className={`sidebar ${show ? "show1" : null}`}>
                    <nav className="nav1">
                        <div>
                            <div className="nav-list">
                                <Link
                                    to={`/admindashboard`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-grid-1x2"></i>
                                    <span className="nav-link-name">Dashboard</span>
                                </Link>
                                <Link
                                    to={`/admincustomers`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                    <span className="nav-link-name">Customers</span>
                                </Link>
                                <Link
                                    to={`/adminstaff`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Staff</span>
                                </Link>
                                <Link
                                    to={`/adminorders`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Orders</span>
                                </Link>
                                <Link
                                    to={`/adminchat`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-chat-left-text"></i>
                                    <span className="nav-link-name">Chats</span>
                                </Link>
                                <Link
                                    to={`/adminticket`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-ticket-detailed"></i>
                                    <span className="nav-link-name">Tickets</span>
                                </Link>
                                <Link
                                    to={`/adminservice`}
                                    className="nav-linksidebar active-bar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Services</span>
                                </Link>
                                {/* <Link
                                    to={`/admintask`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Tasks</span>
                                </Link> */}
                                <hr />
                                {/* <Link
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-webcam"></i>
                                    <span className="nav-link-name">Profile</span>
                                </Link> */}
                                <Link onClick={adminLogout}
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-gear"></i>
                                    <span className="nav-link-name">Logout</span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </aside>

                <div className="home-container">

                    <div className="container pt-4">
                        <div className="row">
                            <div class="col-lg-12 table-column">
                                <h2>{service.name}</h2>
                                <h5>Category : {service.category}</h5>
                                <p>Description : <br></br>{service.details}</p>
                                {service.video_link ? <embed width="560" height="315" src={service.video_link} title="YouTube Video" allowFullScreen X-Frame-Options="anonymous" ></embed> : '' }
                                <p>Documents : <br></br><ul>{service.documents.map((doc) => { return (<><li>{doc.doc_name}</li> </>); })}</ul></p>
                                <p>Timeline : {service.sla}</p>
                                <p>Price : <strong>Rs.{service.price}/-</strong></p>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </>
    )
}

export default Adminservicedetails