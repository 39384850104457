import React from "react";
// import Navbar from "./navbar";
// import Footer from "./footer";
import { Link } from "react-router-dom";
export const Home = () => {
  return (
    <>
      {/* <Navbar/> */}
      {/* <div className="container-fluid banner-container mt-5 pb-4">
        <div className="row">
          <div className="col-lg-7">
            <div className="banner-content mt-5 pt-3">
              <h4>Finances Simplified, Success Amplified.</h4>
              <p className="pt-4">
                Your Next Door Financial Doctors to Launch, Manage & Expand your
                Personal & <br /> Corporate Finance, Taxes, Legal, Secretarial &
                More. Your Next Door Financial <br /> Doctors to Launch, Manage
                & Expand your Personal & Corporate Finance, Taxes, <br /> Legal,
                Secretarial & More.
              </p>
              <div className="banner-button pt-4">
                <button className="quote-now me-3">Get Started</button>
                <button className="learn-btn">Learn More</button>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner-side pt-5 mt-3">
              <img src="banner.png" alt="" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="row new-nav-row">
          <div className="col-lg-2">
            <img src="logo.png" width="135" height="61" alt="" />
          </div>
        </div>
      </div>
      <div className="container-fluid pt-2 pb-3 new-banner-container">
        <div className="row new-banner-row">
          <div className="col-lg-6">
            <div className="new-banner">
              <img src="images/new-banner.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="new-banner-content">
              <h3>Your Next Big Idea Starts <br />
                Here</h3>
              <div className="new-banner-button pt-5">
                <Link to={'/login'}><button className="me-3">Login</button></Link>
                <Link to={'/register'}><button>Register</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid new-footer">
        <div className="container">
          <div className="row footer-new-row">
            <div className="col-lg-6">
              <div className="policy-info">
                <a href="https://www.taxaj.com/policies" className='foot-info'>Privacy Policy</a>
                <a href="https://www.taxaj.com/policies" className='foot-info'>Refund & Cancellation</a>
                <a href="https://www.taxaj.com/policies" className='foot-info'>Terms & Condition</a>
                <a href="https://www.taxaj.com/blogs/" className='foot-info'>Blog</a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex contact-info1">
                <p className="cont-number-head1 me-3">
                  {" "} <i className='bx bx-phone-call me-2'></i>
                  <Link to="tel:8802812345" className="cont-number1">+918802812345</Link>{" "}
                </p>
                <p className="c-head">
                  {" "} <i className="bi bi-envelope me-2"></i>
                  <Link to="" className="cont-mail1">connect@taxaj.com</Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </>
  );
};
