import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Adminservice = () => {
  const navigate = useNavigate();
  const { apipath, adminLogout } = useAuth();
  const admintoken = localStorage.getItem('admintoken');
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(true);
  const [serviceData, setserviceData] = useState();
  const [sdata, setsdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ name: '', category: '' });

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const filteredItems = sdata.filter(item =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredItems);
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };
  const applyFilters = () => {
    let filteredItems = [...sdata];
    Object.keys(filters).forEach(key => {
      const filterValue = filters[key].toLowerCase();
      if (filterValue) {
        filteredItems = filteredItems.filter(item =>
          item[key].toLowerCase().includes(filterValue)
        );
      }
    });
    setFilteredData(filteredItems);
  };
  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'adminservices',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + admintoken
            }
          }
        );
        setserviceData(response.data);
        setsdata(response.data.services);
        setFilteredData(response.data.services);
        if (response.data.status === "false") {
          localStorage.removeItem('admintoken');
          navigate('/login');
        }
      }
      catch (error) {
        console.error('Error fetching orders data:', error);
        if (localStorage.getItem('admintoken')) {
          localStorage.removeItem('admintoken');
        }
        navigate('/login');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const scolumns = [
    { name: 'ID', selector: row => row.id, sortable: true, width: '80px' },
    { name: 'Service Name', selector: row => row.name, sortable: true, width: '300px' },
    { name: 'Category', selector: row => row.category, sortable: true, width: '250px' },
    { name: 'Price', selector: row => row.price, sortable: true, width: '120px' },
    { name: 'Timeline', selector: row => row.sla, sortable: true, width: '120px' },
    {
      name: 'Action', cell: row => (
        <>
          <Link to={`/adminservicedetails/${row.id}`}><i class="bi bi-eye viewbtn"></i></Link>
          <Link to={`/adminupdateservice/${row.id}`}><i class="bi bi-pencil viewbtn ms-2"></i></Link>
        </>
      ), width: '120px'
    },
    // Add more columns as needed
  ];
  localStorage.setItem('serviceData', JSON.stringify(serviceData.services));
  // if (serviceData.services.length > 0) {
  //   var rowdata = serviceData.services;
  //   var rows = rowdata.map((serv) => {
  //     return (
  //       <>
  //         <tr>
  //           <td>{serv.id}</td>
  //           <td>{(serv.name.length > 50) ? serv.name.substr(0,50)+'...' : serv.name.substr(0,50)}</td>
  //           <td>Rs.{serv.price}</td>
  //           <td>{serv.sla}</td>
  //           <td>{serv.category}</td>
  //           <td>
  //             <Link to={`/adminservicedetails/${serv.id}`}><i class="bi bi-eye viewbtn"></i></Link>
  //             <Link to={`/adminupdateservice/${serv.id}`}><i class="bi bi-pencil viewbtn"></i></Link>
  //             </td>
  //         </tr>
  //       </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No services.";
  // }
  return (
    <>
      <Admindashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/admindashboard`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-grid-1x2"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link
                  to={`/admincustomers`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/adminstaff`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Staff</span>
                </Link>
                <Link
                  to={`/adminorders`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Orders</span>
                </Link>
                <Link
                  to={`/adminchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/adminticket`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/adminservice`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Services</span>
                </Link>
                {/* <Link
                  to={`/admintask`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link> */}
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={adminLogout}
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">

          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>Our Services</h5>
                {/* <div class="new-filter">
                  <div class="input-filterbox">
                    <input
                      class="search me-2"
                      type="search"
                      placeholder="Search..."
                    />
                    <input type="date" className="form-control" />
                  </div>
                </div> */}
                <div>
                  <div className="row m-0" id="table_detail">
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Search by name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="category"
                        placeholder="Search by Category"
                        value={filters.category}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-2 p-1">
                      <button onClick={applyFilters}>Search</button>
                    </div>
                  </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={sdata.length}
                    paginationRowsPerPageOptions={[sdata.length]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>ID</th>
                      <th>Service Name</th>
                      <th>Price</th>
                      <th>Timeline</th>
                      <th>Category</th>
                      <th>Action</th>
                    </tr>
                    {rows}
                    <tr>
                      <td>#5688</td>
                      <td>Private Limited Company</td>
                      <td>10,000</td>
                      <td>10 days</td>
                      <td>Legal Services</td>
                      <td>Income Tax</td>
                      <td>Aadhar, Pan, el....</td>
                      <td>
                        <i class="bi bi-arrow-bar-up" data-bs-toggle="modal" data-bs-target="#exampleModal"></i> &nbsp;
                        <i class="bi bi-eye"></i>
                      </td>
                    </tr>
                  </table> */}
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Update Service</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="">Service Name</label>
                      <input type="text" className="form-control" placeholder="Private Limited Company" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="">Service Fee</label>
                      <input type="text" className="form-control" placeholder="10,000" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="">Service Description</label>
                      <textarea type="text" className="form-control" placeholder="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable." />
                    </div>
                    <div className="service-update-btn">
                      <button>Update</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Adminservice