import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import iziToast from 'izitoast';
import { useAuth } from '../AuthContext';
import { Link, useNavigate } from "react-router-dom";
const Otp = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  if(localStorage.getItem('token')){
    localStorage.removeItem('token');
  }
  
  const udata = localStorage.getItem('userData');
  if(udata === null){
    window.location.href="/register";
  }
  
      const user_data = JSON.parse(udata);
      const email = user_data.email;
      const length = 6;
      const [otp, setOtp] = useState(Array(length).fill(''));
      const inputRefs = useRef([]);
      
      const [userData, setUserData] = useState({
        name: user_data.name,
        email: user_data.email,
        phone: user_data.phone,
        password: user_data.password,
        uotp:'',
      });
      const [otpData] = useState({
        email: user_data.email,
      });

      const handleChange = (e, index) => {
        var value = e.target.value;
        var uotp = document.getElementById('uotp');

        // Only allow numeric input and limit to one character
        if (/^[0-9]$/.test(value)) {
          setOtp((prevOtp) => {
            var newOtp = [...prevOtp];
            newOtp[index] = value;
            uotp.value = newOtp.join("");
            setUserData({ ...userData, uotp: document.getElementById('uotp').value });
            return newOtp;
          });

          // Move to the next input box automatically
          if (index < length - 1 && value !== '') {
            inputRefs.current[index + 1].focus();
          }
        }
      };

      const handleKeyUp = (e, index) => {
        var uotp = document.getElementById('uotp');
        // Move to the previous input box on backspace
        if (e.key === 'Backspace') {
          setOtp((prevOtp) => {
            var newOtp = [...prevOtp];
            newOtp[index] = "";
            uotp.value = newOtp.join("");
            setUserData({ ...userData, uotp: document.getElementById('uotp').value });
            return newOtp;
          });
          inputRefs.current[index].value = "";
          if( index > 0){
            inputRefs.current[index - 1].focus();
          }
        }
        
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        
        console.log(userData);
        axios.post(apipath+'register', userData)
          .then((response) => {
            var data = response.data;
            if(data.status === "false"){
              iziToast.error({
                message: data.message,
                position: "topCenter"
              });
            }
            else if(data.status === "true"){
              iziToast.success({
                message: data.message,
                position: "topCenter"
              });
              navigate('/login');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
      
      const handleSendOtp = (e) => {
        e.preventDefault();
        axios.post(apipath+'sendOtp', otpData)
          .then((response) => {
            var data = response.data;
            if(data.status === "false"){
              iziToast.error({
                message: data.message,
                position: "topCenter"
              });
            }
            else if(data.status === "true"){
              iziToast.success({
                message: data.message,
                position: "topCenter"
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
      return (
        <>
          <div className="container-fluid register-container">
            <div className="row main-register-row">
              <div className="col-lg-4">
                <div className="register-box-left">
                  <img src="images/reg-icon.png" alt="" />
                </div>
              </div>
              <div className="col-lg-8 register-column">
                <div className="row otp-row justify-content-center pt-5 mt-5">
                  <div className="col-lg-6 mt-5">
                    <div className="register-box-right">
                      <h6 className="pt-3">
                        {" "}
                        Enter The Code {" "}
                      </h6>
                      <small className="pb-4">Enter The 6 Digit OTP sent to your email <span>{email}</span></small>
                      <form className="register-form pt-4" onSubmit={handleSubmit}>
                        <div className="otp-inputbox mb-2">
                          {otp.map((digit, index) => (
                            <input
                              key={index}
                              type="text"
                              value={digit}
                              onChange={(e) => handleChange(e, index)}
                              onKeyUp={(e) => handleKeyUp(e, index)}
                              maxLength="1"
                              ref={(inputRef) => (inputRefs.current[index] = inputRef)}
                              required
                              className='form-control'
                            />
                          ))}
                        </div>
                        <input type='hidden' name='uotp' id='uotp' />
                        <small onClick={handleSendOtp}> <Link> Resend OTP </Link> </small>
                        <button class="register-btn mt-5 mb-4">
                          <span>Continue</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
}

export default Otp