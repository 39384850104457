import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
const Mydocuments = () => {
  const [show, setShow] = useState(true);
  // const { customerId } = useParams();
  const { apipath, logout } = useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [docData, setdocData] = useState();
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath+'documents',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        );
        setdocData(response.data);
        if (response.data.status === "false") {
          localStorage.removeItem('token');
          navigate('/login');
        }
      }
      catch (error) {
        console.error('Error fetching documents data:', error);
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
        navigate('/login');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);
  console.log(docData);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (docData.documents.length > 0) {
    var rowdata = docData.documents;
    var rows = rowdata.map(() => {
      return `<tr>< td > <input class="form-check-input" type="checkbox" /></td >
      <td>Pan Of Applicant</td>
      <td>Pan Card</td>
      <td>18-10-2023</td>
      <td>Completed</td>
      <td><i class="bi bi-download"></i></td>
    </tr > `;
    });
  }
  else {
    rows = "No documents.";
  }

return (
  <>
    <Dashnavbar />
    <>

    </>
    <main className={show ? "space-toggle" : null}>
      <header className={`header ${show ? "space-toggle" : null}`}>
        <div className="header-toggle" onClick={() => setShow(!show)}>
          <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
        </div>
      </header>

      <Sidebar activePage={window.location.pathname.split('/')[1]} />

      <div className="home-container">

        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="dash-infobox">
                <div className="dash-head">
                  <h5>All Documents</h5>
                  <img src="images/my-doc.png" width={30} height={30} alt="" />
                </div>
                <h4>{docData.total_docs}</h4>
                <Link className="dash-link">View</Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="dash-infobox">
                <div className="dash-head">
                  <h5>Expired Documents</h5>
                  <img src="images/dash2.png" width={30} height={30} alt="" />
                </div>
                <h4>{docData.expired_docs}</h4>
                <Link className="dash-link">View</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-4">
          <div className="row">
            <div class="col-lg-12 table-column">
              <h5>My Documenst</h5>
              <div class="new-filter">
                <div class="input-filterbox">
                  <input
                    class="search me-2"
                    type="search"
                    placeholder="Search..."
                  />
                  <input type="date" className="form-control" />
                </div>
              </div>
              <div>
                <table id="table_detail">
                  <tr>
                    <th><input class="form-check-input" type="checkbox" /></th>
                    <th>Documents Category</th>
                    <th>Document Name</th>
                    <th>Uploaded Date & Time</th>
                    <th>Payment Status</th>
                    <th>Action</th>
                  </tr>
                  {/* <tr>
                    <td><input class="form-check-input" type="checkbox" /></td>
                    <td>Pan Of Applicant</td>
                    <td>Pan Card</td>
                    <td>18-10-2023</td>
                    <td>Completed</td>
                    <td><i class="bi bi-download"></i></td>
                  </tr> */}
                  {rows}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>
)
}

export default Mydocuments