import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
const Admineditstaff = () => {
    const { apipath, adminLogout } = useAuth();
    const [show, setShow] = useState(true);
    const { id } = useParams();
    console.log(id);
    const navigate = useNavigate();
    const admintoken = localStorage.getItem('admintoken');
    const sdata = localStorage.getItem('serviceData');
    const servicedata = JSON.parse(sdata);
    
    var service = {
        id: "",
        name: "",
        price: "",
        details: "",
        video_link: "",
    }
    for (let i = 0; i < servicedata.length; i++) {
        if (servicedata[i].id == id) {
            service = servicedata[i];
            console.log(service);
        }
    }
    const [servData, setservData] = useState({
        id: service.id,
        name: service.name,
        price: service.price,
        details: service.details,
        video_link: service.video_link,
    });
    
    const checkservice = async () => {
        if (service.id === "") {
            iziToast.error({
                message: "Service not found.",
                position: "topCenter"
            });
            navigate('/adminservices');
        }
    }
    useEffect(() => {
        checkservice();
        document.getElementById('sname').value = service.name;
        document.getElementById('sprice').value = service.price;
        document.getElementById('sdetails').value = service.details;
        document.getElementById('svideolink').value = service.video_link;
    }, []);

    const handleChange = (e) => {
        setservData({ ...servData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(apipath+'updateservice', servData,
          {
            headers: {
              Authorization: 'Bearer ' + admintoken
            }
          })
            .then((response) => {
                var data = response.data;
                if (data.status === "false") {
                    iziToast.error({
                        message: data.message,
                        position: "topCenter"
                    });
                }
                else if (data.status === "true") {
                    iziToast.success({
                        message: data.message,
                        position: "topCenter"
                    });
                    navigate('/adminservice');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return (
        <>
            <Admindashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <aside className={`sidebar ${show ? "show1" : null}`}>
                    <nav className="nav1">
                        <div>
                            <div className="nav-list">
                                <Link
                                    to={`/admindashboard`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-grid-1x2"></i>
                                    <span className="nav-link-name">Dashboard</span>
                                </Link>
                                <Link
                                    to={`/admincustomers`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                    <span className="nav-link-name">Customers</span>
                                </Link>
                                <Link
                                    to={`/adminstaff`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Staff</span>
                                </Link>
                                <Link
                                    to={`/adminorders`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Orders</span>
                                </Link>
                                <Link
                                    to={`/adminchat`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-chat-left-text"></i>
                                    <span className="nav-link-name">Chats</span>
                                </Link>
                                <Link
                                    to={`/adminticket`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-ticket-detailed"></i>
                                    <span className="nav-link-name">Tickets</span>
                                </Link>
                                <Link
                                    to={`/adminservice`}
                                    className="nav-linksidebar active-bar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Services</span>
                                </Link>
                                {/* <Link
                                    to={`/admintask`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Tasks</span>
                                </Link> */}
                                <hr />
                                {/* <Link
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-webcam"></i>
                                    <span className="nav-link-name">Profile</span>
                                </Link> */}
                                <Link onClick={adminLogout}
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-gear"></i>
                                    <span className="nav-link-name">Logout</span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </aside>

                <div className="home-container">

                    <div className="container pt-4">
                        <div className="row">
                            <div class="col-lg-12 table-column">
                                <h5>Edit Service</h5>
                                <div>

                                    <form className="register-form" onSubmit={handleSubmit}>
                                        <div className="mb-5">
                                            <label>Service Name</label>
                                            <input type="text" name="name" id="sname" required className="form-control" onChange={handleChange} placeholder="Service Name" />
                                        </div>
                                        <div className="mb-5">
                                            <label>Service Price</label>
                                            <input type="text" name="price" id="sprice" pattern="\d*" required className="form-control" onChange={handleChange} placeholder="Service Price" />
                                        </div>
                                        <div className="mb-5">
                                            <label>Service Details</label>
                                            <textarea rows="6" name="details" id="sdetails" required className="form-control" onChange={handleChange} placeholder="Service Details"></textarea>
                                        </div>
                                        <div className="mb-5">
                                            <label>Youtube Link</label>
                                            <input type="text" name="video_link" id="svideolink" className="form-control" onChange={handleChange} placeholder="https://www.youtube.com/embed/" />
                                        </div>
                                        <button class="register-btn mb-4">
                                            <span>Update Service</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Admineditstaff