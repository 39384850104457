import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import { useAuth } from '../AuthContext';
const Expireddocuments = () => {
  const { logout } = useAuth();
    const [show, setShow] = useState(true);
const { customerId } = useParams();
  return (
   <>
        <Dashnavbar />
     <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/dashboard`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-grid-1x2"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                {/* <Link
                  to={`/mydocuments`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">My Documents</span>
                </Link> */}
                <Link
                  to={`/myorders`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">My Orders</span>
                </Link>
                <Link
                  to={`/chat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/raiseticket`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Raise Ticket</span>
                </Link>
                <Link
                  to={`/services`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Services</span>
                </Link>
                <hr />
                <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link>
                <Link onClick={logout}
                  to={''}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">

        <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="dash-infobox">
                  <div className="dash-head">
                    <h5>All Documents</h5>
                    <img src="images/my-doc.png" width={30} height={30} alt="" />
                  </div>
                  <h4>7</h4>
                  <Link className="dash-link">View</Link>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="dash-infobox">
                  <div className="dash-head">
                    <h5>Expired Documents</h5>
                    <img src="images/dash2.png" width={30} height={30} alt="" />
                  </div>
                  <h4>2</h4>
                  <Link className="dash-link">View</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>Expired Documents</h5>
                <div class="new-filter">
                  <div class="input-filterbox">
                  <input
                      class="search me-2"
                      type="search"
                      placeholder="Search..."
                    />
                    <input type="date" className="form-control" />
                  </div>
                </div>
                <div>
                  <table id="table_detail">
                    <tr>
                        <th><input class="form-check-input" type="checkbox" /></th>
                      <th>Documents Category</th>
                      <th>Document Name</th>
                      <th>Uploaded Date & Time</th>
                      <th>Payment Status</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                    <td><input class="form-check-input" type="checkbox" /></td>
                      <td>Pan Of Applicant</td>
                      <td>Pan Card</td>
                      <td>18-10-2023</td>
                      <td>Completed</td>
                      <td><i class="bi bi-download"></i></td>
                    </tr>
                    <tr>
                    <td><input class="form-check-input" type="checkbox" /></td>
                      <td>Proof Of Business Registration or Certificate</td>
                      <td>Pan Card</td>
                      <td>18-10-2023</td>
                      <td>Completed</td>
                      <td><i class="bi bi-download"></i></td>
                    </tr>
                    <tr>
                    <td><input class="form-check-input" type="checkbox" /></td>
                      <td>Pan Of Applicant</td>
                      <td>Pan Card</td>
                      <td>18-10-2023</td>
                      <td>Completed</td>
                      <td><i class="bi bi-download"></i></td>
                    </tr>
                    <tr>
                    <td><input class="form-check-input" type="checkbox" /></td>
                      <td>Proof Of Business Registration or Certificate</td>
                      <td>Pan Card</td>
                      <td>18-10-2023</td>
                      <td>Completed</td>
                      <td><i class="bi bi-download"></i></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
   </>
  )
}

export default Expireddocuments