import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apipath = "https://taxaj.in/backend/public/api/";
  const filepath = "https://taxaj.in/backend/public/user_documents/";
  // const apipath = "http://localhost/taxaj/public/api/";
  // const filepath = "http://localhost/taxaj/public/user_documents/";
  const login = () => {
    setIsLoggedIn(true);
    // Additional login logic (e.g., setting tokens, etc.)
  };

  const logout = () => {
    localStorage.removeItem('token');
    window.location.href = "/login";
    // Additional logout logic (e.g., clearing tokens, etc.)
  };
  const adminLogout = () => {
    localStorage.removeItem('admintoken');
    window.location.href = "/adminlogin";
    // Additional logout logic (e.g., clearing tokens, etc.)
  };
  const staffLogout = () => {
    localStorage.removeItem('stafftoken');
    window.location.href = "/stafflogin";
    // Additional logout logic (e.g., clearing tokens, etc.)
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, apipath, filepath, login, logout, adminLogout , staffLogout}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);