import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
const Ticketdetail = () => {
    const [show, setShow] = useState(true);
    const { logout, apipath } = useAuth();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [ticketData, setticketData] = useState();

    const [images, setImages] = useState([]);
    const [replyData, setreplyData] = useState({
        'id': id,
        'message': ''
    });

    const handleMessage = (e) => {
        setreplyData({ ...replyData, ['message']: e.target.innerHTML });
    };

    const applystyle = (style, value = null) => {
        document.execCommand(style, false, value);
    };
    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        console.log(files);
        setImages(files);
    };
    useEffect(() => {
        setreplyData({ ...replyData, ['attachment']: images });
    }, [images]);
    // useEffect(() => {
    //     console.log(replyData);
    // }, [replyData]);

    const checkAuthentication = async () => {
        try {
            const response = await axios.post(
                apipath + 'ticket_detail/' + id,
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                }
            );
            setticketData(response.data);
            if (response.data.status === "false") {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
        catch (error) {
            console.error('Error fetching orders data:', error);
            // if (localStorage.getItem('token')) {
            //   localStorage.removeItem('token');
            // }
            // navigate('/login');

        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        checkAuthentication();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        var messagetext = document.getElementById('editor');
        var msgtxt = messagetext.textContent.trim();
        console.log(msgtxt.length);
        if (msgtxt.length < 3) {
            iziToast.error({
                message: "Message must be 3 characters long.",
                position: "topCenter"
            });
        }
        else {
            axios.post(apipath + 'ticket_reply', replyData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    }
                })
                .then((response) => {
                    var data = response.data;
                    if (data.status === "false") {
                        iziToast.error({
                            message: data.message,
                            position: "topCenter"
                        });
                    }
                    else if (data.status === "true") {
                        iziToast.success({
                            message: data.message,
                            position: "topCenter"
                        });
                        messagetext.innerHTML = "";
                        document.getElementById('attachfiles').value = '';
                        checkAuthentication();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    var ticket = ticketData.ticket;
    if (ticket.attachment.length > 0) {
        var tattach = ticket.attachment.map((atch) => {
            return (
                <>
                    <a class="text-primary" href={atch} download>
                        <button className="down-btn p-1 lh-1"> Download File &nbsp;  <i class="bi bi-download"></i> </button>
                    </a>
                </>
            )
        })
    }
    else {
        tattach = "";
    }
    if (ticket.replies.length > 0) {
        var rowdata = ticket.replies;
        var replies = rowdata.map((reply) => {
            if (reply.attachments.length > 0) {
                var rattach = reply.attachments.map((attach) => {
                    return (
                        <>
                            <a class="text-primary" href={attach} download>
                                <button className="down-btn p-1 lh-1"> Download File &nbsp;  <i class="bi bi-download"></i> </button>
                            </a>
                        </>
                    )
                })
            }
            else {
                rattach = "";
            }
            return (
                <>
                    <div class="col-lg-12">
                        <div class="row m-0 justify-between">
                            <div class="col">
                                <h6 class="m-0"><b>{reply.reply_name}</b> &nbsp;&nbsp; <small>{reply.replydate}</small></h6>
                                <p class="m-0 pt-2" dangerouslySetInnerHTML={{ __html: reply.message }}>
                                </p>
                                <p class="m-0 pt-1">
                                    {rattach}
                                </p>
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </>
            );
        });
    }
    else {
        replies = "";
    }

    return (
        <>
            <Dashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <Sidebar activePage={'raiseticket'} />

                <div className="home-container">
                    <div className="container pt-4 pb-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ticket-information">
                                    <div className="ticket-heading pb-2">
                                        <h4>Ticket Information</h4>
                                        <div className="ticket-infoform row mt-3">
                                            <div className="mb-1 col-lg-4">
                                                <h6 class="fw-bold"> Name </h6>
                                                <p>{ticket.name}</p>
                                                {/* <input type="text" onChange={handleChange} required name="name" className="form-control" placeholder="Name" /> */}
                                            </div>
                                            <div className="mb-1 col-lg-4">
                                                <h6 class="fw-bold"> Email </h6>
                                                <p>{ticket.email}</p>
                                                {/* <input type="email" onChange={handleChange} required name="email" className="form-control" placeholder="Email" /> */}
                                            </div>
                                            <div className="mb-1 col-lg-4">
                                                <h6 class="fw-bold"> Department </h6>
                                                <p>{ticket.department}</p>
                                            </div>
                                            <div className="mb-1 col-lg-4">
                                                <h6 class="fw-bold">Relationship Manager </h6>
                                                <p>{ticket.staff_name}</p>
                                            </div>
                                            <div className="mb-1 col-lg-4">
                                                <h6 class="fw-bold"> Related Service </h6>
                                                <p>{ticket.service_name}</p>
                                            </div>
                                            <div className="mb-1 col-lg-4">
                                                <h6 class="fw-bold"> Priority </h6>
                                                <p>{ticket.priority}</p>
                                            </div>
                                            <div className="mb-1 col-lg-12">
                                                <h6 class="fw-bold"> Subject </h6>
                                                <p>{ticket.subject}</p>
                                                {/* <input type="text" onChange={handleChange} required name="subject" className="form-control" placeholder="Document Uploading issue" /> */}
                                            </div>

                                            <h6 class="fw-bold pt1"> Message </h6>
                                            <div className="text-editor pb-4">
                                                <div className="editor" id="editor-1" dangerouslySetInnerHTML={{ __html: ticket.message }}>
                                                </div>
                                            </div>
                                            {ticket.attachment !== null ? <h6 class="fw-bold pt1">Attachments</h6> : ''}
                                            <div className=" col-lg-8 attachment-file">
                                                {tattach}
                                                {/* <input type="file" name="attachment" onChange={handleImageChange} multiple className="form-control" placeholder="Akash Rawat" />
                                                    <small>
                                                    Allowed file extensions : .jpg, .jpeg, .gif, .png,
                                                    .pdf, .doc, .docx
                                                    </small> */}
                                            </div>
                                        </div>

                                        <form className="ticket-infoform row mt-3" onSubmit={handleSubmit}>

                                            <h5 class="mb-3">Replies</h5>
                                            <div class="replybox">
                                                {replies}
                                            </div>
                                            {(ticket.status === 'Closed') ? <h3 className="pt-4">Ticket Closed </h3> : 
                                            <>
                                            <h4 className="pt-4">Send Reply </h4>
                                            <div className="text-editor pb-4">
                                                <div className="editor" id="editor-1">
                                                    <div className="toolbar">
                                                        <Link href="#" onClick={() => applystyle('undo')} data-command="undo" data-toggle="tooltip" data-placement="top" title="Undo" >
                                                            <i className="fa fa-undo"></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('redo')} data-command="redo" data-toggle="tooltip" data-placement="top" title="Redo" >
                                                            <i className="fa fa-redo "></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('removeFormat')} data-command="removeFormat" data-toggle="tooltip" data-placement="top" title="Clear format" >
                                                            <i className="fa fa-times "></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('bold')} data-command="bold" data-toggle="tooltip" data-placement="top" title="Bold" >
                                                            <i className="fa fa-bold"></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('italic')} data-command="italic" data-toggle="tooltip" data-placement="top" title="Italic" >
                                                            <i className="fa fa-italic"></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('underline')} data-command="underline" data-toggle="tooltip" data-placement="top" title="Underline" >
                                                            <i className="fa fa-underline"></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('strikeThrough')} data-command="strikeThrough" data-toggle="tooltip" data-placement="top" title="Stike through" >
                                                            <i className="fa fa-strikethrough"></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('justifyLeft')} data-command="justifyLeft" data-toggle="tooltip" data-placement="top" title="Left align" >
                                                            <i className="fa fa-align-left"></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('justifyCenter')} data-command="justifyCenter">
                                                            <i className="fa fa-align-center" data-toggle="tooltip" data-placement="top" title="Center align" ></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('justifyRight')} data-command="justifyRight" data-toggle="tooltip" data-placement="top" title="Right align" >
                                                            <i className="fa fa-align-right"></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('insertUnorderedList')} data-command="insertUnorderedList" data-toggle="tooltip" data-placement="top" title="Unordered list" >
                                                            <i className="fa fa-list-ul"></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('insertOrderedList')} data-command="insertOrderedList" data-toggle="tooltip" data-placement="top" title="Ordered list" >
                                                            <i className="fa fa-list-ol"></i>
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('formatBlock', 'H1')} data-command="h1" data-toggle="tooltip" data-placement="top" title="H1" >
                                                            H1
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('formatBlock', 'H2')} data-command="h2" data-toggle="tooltip" data-placement="top" title="H2" >
                                                            H2
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('formatBlock', 'H3')} data-command="h3" data-toggle="tooltip" data-placement="top" title="H3" >
                                                            H3
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('formatBlock', 'H4')} data-command="h4" data-toggle="tooltip" data-placement="top" title="H4" >
                                                            H4
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('formatBlock', 'H5')} data-command="h5" data-toggle="tooltip" data-placement="top" title="H5" >
                                                            H5
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('formatBlock', 'H6')} data-command="h6" data-toggle="tooltip" data-placement="top" title="H6" >
                                                            H6
                                                        </Link>
                                                        <Link href="#" onClick={() => applystyle('formatBlock', 'P')} data-command="p" data-toggle="tooltip" data-placement="top" title="Paragraph" >
                                                            P
                                                        </Link>
                                                    </div>
                                                    <div id="editor" onInput={handleMessage} className="editorAria" contentEditable="true">

                                                    </div>
                                                </div>
                                            </div>
                                            <h4>Attachment</h4>
                                            <div className=" col-lg-8 attachment-file">
                                                <input type="file" name="attachment" id="attachfiles" onChange={handleImageChange} multiple className="form-control" placeholder="" />
                                                <small>
                                                    Allowed file extensions : .jpg, .jpeg, .gif, .png,
                                                    .pdf, .doc, .docx
                                                </small>
                                            </div>
                                            <div className="sub-cancel-btns pt-4">
                                                <button className="tick-submit me-3">Submit</button>
                                                {/* <button className="tick-cancel">Cancel</button> */}
                                            </div>
                                            </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Ticketdetail;
