import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
import eko from '../eko';
const Chat = () => {
    const [show, setShow] = useState(true);
    const { logout, apipath } = useAuth();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const chatContainerRef = useRef(null);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);

    const [images, setImages] = useState([]);
    const [message, setmessage] = useState('');
    const [uid, setuid] = useState('');
    const [ordrid, setordrid] = useState('');
    const [activeChat, setactiveChat] = useState(null);
    const [myorders, setOrders] = useState({
        'orders': [],
    });
    const [myMessages, setmyMessages] = useState({
        'messages': [],
    });

    const checkAuthentication = async () => {
        try {
            axios.post(apipath + 'chatOrders',
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(response => {
                    setOrders(response.data);
                    setuid(response.data.user_id);
                    // console.log(response.data);
                    if (response.data.status === "false") {
                        localStorage.removeItem('token');
                        navigate('/login');
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching messages:', error);
                });
        }
        catch (error) {
            console.error('Error fetching dashboard data:', error);
            if (localStorage.getItem('token')) {
                localStorage.removeItem('token');
            }
            navigate('/login');

        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        checkAuthentication();
    }, []);

    const getChat = (oid) => {
        // console.log(oid);
        axios.post(apipath + 'messages',
            { 'order_id': oid },
            {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            .then(response => {
                setmyMessages(response.data);
                checkAuthentication();
                // console.log(response.data);
                if (response.data.status === "false") {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });

    }

    useEffect(() => {
        if(id){
            getChat(id);
            setactiveChat('chat' + id);
            setordrid(id);
        }
    }, [id]);

    useEffect(() => {
        // console.log(myMessages);
        const handleNewMessage = (data) => {
            // console.log(data);
            setmyMessages(prevState => {
                return {
                    ...prevState,
                    messages: [...prevState.messages, data.message]
                };
            });
        };

        const channel = eko.channel('chat.' + ordrid);
        channel.listen('.message.sent', handleNewMessage);

        // Cleanup function to unsubscribe from the channel
        return () => {
            channel.stopListening('.message.sent', handleNewMessage);
        };
    }, [ordrid]);

    useEffect(() => {
        const handleNewChat = (data) => {
            checkAuthentication();
        };

        const channel = eko.channel('getchat');
        channel.listen('.chat.sent', handleNewChat);

        // Cleanup function to unsubscribe from the channel
        return () => {
            channel.stopListening('.chat.sent', handleNewChat);
        };
    }, []);

    const handleChange = (e) => {
        setmessage(e.target.value);
    };

    useEffect(() => {
        // Scroll to the bottom of the chat container whenever myMessages.messages change
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, [myMessages.messages]);

    const handleSubmit = (e) => {
        e.preventDefault();
        var mymessage = document.getElementById('message');
        var msg = mymessage.value.trim();
        if (msg.length < 1) {
            iziToast.error({
                message: "Enter valid message.",
                position: "topCenter"
            });
        }
        else {
            document.getElementById('sendbtn').setAttribute('disabled','disabled');
            var data = {
                'user_id': uid,
                'order_id': ordrid,
                'message': message,
            }
            axios.post(apipath + 'sendmessage', data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    }
                })
                .then((response) => {
                    // console.log(response);
                    document.getElementById('sendbtn').removeAttribute('disabled');
                    var data = response.data;
                    if (data.status === "false") {
                        iziToast.error({
                            message: data.message,
                            position: "topCenter"
                        });
                    }
                    else if (response.status === 200) {
                        iziToast.success({
                            message: "Message Sent",
                            position: "topCenter"
                        });
                        mymessage.value = '';
                        // eko.channel('chat').listen('.message.sent', (data) => {
                        //     setMessages([...messages, data.message]);
                        //     console.log(data);
                        // });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };
    const showChat = (oid) => {
        // setuserid({ ...userid, ['oid']: oid });
        console.log(oid);
        setordrid(oid);
        setactiveChat('chat' + oid);
        getChat(oid);
    }
    if (myMessages.messages.length > 0) {
        // console.log("find some messages");
        var mymsgs = myMessages.messages;
        // console.log(mymsgs);
        var showmsg = mymsgs.map((msg) => {
            if (msg.send_by == msg.user_id) {
                var sentmessage =
                    <>
                        <div class="received-chat">
                            <div class="main-chat-box">
                                <div class="received-text me-2">
                                    <p>{msg.message}</p>
                                </div>
                                <div class="rec-date-time-info">
                                    <small>{msg.msgtime}</small>
                                </div>
                            </div>
                            <div class="msg-img pb-4">
                                <img src="/images/chat.png" class="rounded-circle" width="40" height="40" alt="" />
                            </div>
                        </div>
                    </>
            }
            else {
                sentmessage =
                    <>
                        <div class="sent-chat me-2">
                            <div class="msg-img me-2 pb-4">
                                <img src="/images/chat.png" class="rounded-circle" width="40" height="40" alt="" />
                            </div>
                            <div class="main-chat-box">
                                <div class="sent-text">
                                    <p>{msg.message}</p>
                                </div>
                                <div class="date-time-info">
                                    <small>{msg.msgtime}</small>
                                </div>
                            </div>
                        </div>
                    </>
            }
            return (
                <>
                    {sentmessage}
                </>
            );
        });
    }
    else {
        showmsg = "";
    }

    if (loading) {
        return <div ref={chatContainerRef}>Loading...</div>;
    }
    if (myorders.orders.length > 0) {
        var rowdata = myorders.orders;
        var rows = rowdata.map((ordr) => {
            return (
                <>
                    <div className={activeChat === 'chat' + ordr.id ? 'chatuser border rounded p-2 mb-1 active-chat' : 'chatuser border rounded p-2 mb-1'} onClick={() => showChat(ordr.id)}>
                        {ordr.readcount == 0 ? '' : <button type="button" className="btn-sm btn-danger p-0" style={{ 'position': 'absolute', 'top': '5px', 'right': '5px' }}>{ordr.readcount}</button>}
                        <h6 class="mb-0" style={{ 'font-weight': '600' }}>{ordr.order_id}</h6>
                        <small>{ordr.user_name}</small><br></br>
                        <small>{ordr.order_name}</small>
                    </div>
                </>
            );
        });
    }
    else {
        rows = "No Orders.";
    }

    return (
        <>
            <Dashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>


                <Sidebar activePage={window.location.pathname.split('/')[1]} />

                <div className="home-container">
                    <div className="container row pt-4 pb-3">
                        <div class="col-lg-4">
                            <div class="row message-row pb-4">
                                <div class="message-seacrhbox mb-4">
                                    <h4>Orders</h4>
                                </div>
                                <div class="col-lg-12" style={{ 'max-height': '425px', 'overflow': 'auto' }}>
                                    {rows}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="row message-row pb-4">
                                <div class="message-seacrhbox mb-4">
                                    <h4>Chat</h4>
                                </div>
                                <div class="col-lg-12 right-chat-column">
                                    <div ref={chatContainerRef} class="text-chat-section">
                                        {showmsg}
                                    </div>
                                    <div class="type-msg-box mt-4">
                                        <form className="row col-12" onSubmit={handleSubmit}>
                                            <div className="col-md-9">
                                                <input type="text" id="message" onChange={handleChange} placeholder="Type your message here....." class="form-control me-4" />
                                            </div>
                                            <div className="col-md-3">
                                                {ordrid === '' ? '' : <button className="form-control btn btn-success shadow-none" id="sendbtn"> Send </button>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Chat;
