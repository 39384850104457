import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Adminticket = () => {
  const { apipath, adminLogout } = useAuth();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const admintoken = localStorage.getItem('admintoken');
  const [loading, setLoading] = useState(true);
  const [ticketData, setticketData] = useState([]);
  const [staffData, setstaffData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState('');

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilters(value);
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...ticketData];
    const filterValue = filters.toLowerCase(); if (filterValue) {
      filteredItems = filteredItems.filter(item => {
        return Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(filterValue)
        );
      });
    }
    setFilteredData(filteredItems);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  const [assignData, setassignData] = useState({
    id: '',
    ticket_id: '',
  });
  const handleAssignto = (e) => {
    var oid = e.target.getAttribute('data-ticketid');
    setassignData({ ...assignData, ['id']: e.target.value, ['ticket_id']: oid });
  }
  useEffect(() => {
    if (assignData.id !== '') {
      axios.post(apipath + 'assignticket', assignData,
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        })
        .then((response) => {
          var data = response.data;
          if (data.status === "false") {
            iziToast.error({
              message: data.message,
              position: "topCenter"
            });
          }
          else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
          }
        })
        .catch((error) => {
          iziToast.error({
            message: error,
            position: "topCenter"
          });
        });
    }
  }, [assignData]);

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(
        apipath + 'admintickets',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        }
      );
      // console.log(response.data);
      // console.log(response.data.staff);
      setticketData(response.data.tickets);
      setFilteredData(response.data.tickets);
      setstaffData(response.data.staff);
      if (response.data.status === "false") {
        localStorage.removeItem('admintoken');
        navigate('/adminlogin');
      }
    }
    catch (error) {
      console.error('Error fetching orders data:', error);
      if (localStorage.getItem('admintoken')) {
        localStorage.removeItem('admintoken');
      }
      navigate('/adminlogin');

    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleDelete = (id) => {
    axios.post(apipath + 'delete_ticket', {'id':id},
      {
        headers: {
          Authorization: 'Bearer ' + admintoken
        }
      })
      .then((response) => {
        var data = response.data;
        if (data.status === "false") {
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
        else if (data.status === "true") {
          iziToast.success({
            message: data.message,
            position: "topCenter"
          });
          checkAuthentication();
        }
      })
      .catch((error) => {
        iziToast.error({
          message: error,
          position: "topCenter"
        });
      });
  }

  const [statusData, setstatusData] = useState({
    status: '',
    ticket_id: '',
  });
  const handleStatus = (e) => {
    var oid = e.target.getAttribute('data-ticketid');
    setstatusData({ ...statusData, ['status']: e.target.value, ['ticket_id']: oid });
  }
  useEffect(() => {
    if (statusData.status !== '') {
      axios.post(apipath + 'update_ticket_status', statusData,
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        })
        .then((response) => {
          var data = response.data;
          if (data.status === "false") {
            iziToast.error({
              message: data.message,
              position: "topCenter"
            });
          }
          else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
          }
        })
        .catch((error) => {
          iziToast.error({
            message: error,
            position: "topCenter"
          });
        });
    }
  }, [statusData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const scolumns = [
    { name: 'Ticket ID', selector: row => row.ticket_id, sortable: true, width: '100px' },
    { name: 'Name', selector: row => row.user_name, sortable: true, width: '150px' },
    { name: 'Email', selector: row => row.user_email, sortable: true, width: '150px' },
    { name: 'Department', selector: row => row.department, sortable: true, width: '150px' },
    {
      name: 'Assigned To', selector: row => (
        <>
          <select onChange={handleAssignto} style={{ "width": "100%" }} data-ticketid={row.id}>
            <option value={''}>Select Staff</option>
            {
              staffData.map((staf) => {
                return (
                  <>
                    <option selected={row.assigned_to === staf.id} value={staf.id}>{staf.name}</option>
                  </>
                );
              })
            }
          </select>
        </>
      ), sortable: true, width: '150px'
    },
    { name: 'Subject', selector: row => row.subject, sortable: true, width: '150px' },
    { name: 'Related Service', selector: row => row.service_name, sortable: true, width: '150px' },
    {
      name: 'Status', selector: row => (
        <>
          <select class="status_select" style={{ 'width': 'fit-content' }} onChange={handleStatus} data-ticketid={row.id}>
            <option selected={row.status === "Pending"} value={'Pending'}>Pending</option>
            <option selected={row.status === "Open"} value={'Open'}>Open</option>
            <option selected={row.status === "Closed"} value={'Closed'}>Closed</option>
          </select>
        </>
      ), sortable: true, width: '110px'
    },
    { name: 'Date', selector: row => row.date, sortable: true, width: '100px' },
    {
      name: 'Action', cell: row => (
        <>
        <Link to={`/staffticketview/${row.id}`}><i class="bi bi-eye viewbtn"></i></Link> &nbsp;&nbsp;
        <i class="bi bi-trash viewbtn" onClick={()=>{handleDelete(row.id)}}></i>
        </>
      )
    },
    // Add more columns as needed
  ];
  return (
    <>
      <Admindashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/admindashboard`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-grid-1x2"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link
                  to={`/admincustomers`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/adminstaff`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Staff</span>
                </Link>
                <Link
                  to={`/adminorders`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Orders</span>
                </Link>
                <Link
                  to={`/adminchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/adminticket`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/adminservice`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Services</span>
                </Link>
                {/* <Link
                  to={`/admintask`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link> */}
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={adminLogout}
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">

          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>Tickets</h5>
                <div>
                  <div className="row m-0" id="table_detail">
                    <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Search"
                        value={filters.order_name}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-9 col-md-6 col-sm-12 mb-2 p-1">
                      <button className="btn btn-primary shadow-none" onClick={applyFilters}>Search</button>
                    </div>
                  </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>Ticket ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Department</th>
                      <th>Assigned to</th>
                      <th>Subject</th>
                      <th>Related Service</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                    {rows}
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Adminticket