import React, { useState, useEffect } from "react";
import iziToast from "izitoast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
const Details = () => {
    const [show, setShow] = useState(true);
    // const { customerId } = useParams();
    const { logout } = useAuth();
    const navigate = useNavigate();
    const { id } = useParams();
    const sdata = localStorage.getItem('serviceData');
    const servicedata = JSON.parse(sdata);

    var service = {
        id: "",
        name: "",
        email: "",
        phone: "",
        department: "",
        password: "",
    }
    for (let i = 0; i < servicedata.length; i++) {
        if (servicedata[i].id == id) {
            service = servicedata[i];
            console.log(service);
        }
    }
    const [servData, setservData] = useState({
        id: service.id,
        name: service.name,
        email: service.email,
        phone: service.phone,
        department: service.department,
        password: service.password,
    });

    const checkservice = async () => {
        if (service.id === "") {
            iziToast.error({
                message: "Service not found.",
                position: "topCenter"
            });
            navigate('/services');
        }
    }
    useEffect(() => {
        checkservice();
    }, []);

    return (
        <>
            <Dashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <Sidebar activePage={'services'} />

                <div className="home-container">
                    <div className="container pt-4">
                        <div className="row">
                            <div class="col-lg-12 table-column">
                                <h2>{service.name}</h2>
                                <h5>Category : {service.category}</h5>
                                <p>Description : <br></br>{service.details}</p>
                                {service.video_link ? <embed width="560" height="315" src={service.video_link} title="YouTube Video" allowFullScreen X-Frame-Options="anonymous" ></embed> : '' }
                                <p>Documents : <br></br><ul>{service.documents.map((doc) => { return (<><li>{doc.doc_name}</li> </>); })}</ul></p>
                                <p>Timeline : {service.sla}</p>
                                <p>Price : <strong>Rs.{service.price}/-</strong></p>
                                <Link to={'/payment/' + service.id}><button class="btn btn-primary">Buy Now</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Details