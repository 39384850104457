import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
const Raiseticket = () => {
  const [show, setShow] = useState(true);
  const { logout, apipath } = useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [recentData, setrecentData] = useState();

  const [images, setImages] = useState([]);
  const [ticketData, setticketData] = useState({
    'message': ''
  });
  const handleChange = (e) => {
    setticketData({ ...ticketData, [e.target.name]: e.target.value });
  };

  const handleMessage = (e) => {
    setticketData({ ...ticketData, ['message']: e.target.innerHTML });
  };

  const applystyle = (style, value = null) => {
    document.execCommand(style, false, value);
  };
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    console.log(files);
    setImages(files);
  };
  useEffect(() => {
    setticketData({ ...ticketData, ['attachment']: images });
  }, [images]);
  useEffect(() => {
    console.log(ticketData);
  }, [ticketData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    var messagetext = document.getElementById('editor');
    var msgtxt = messagetext.textContent.trim();
    console.log(msgtxt.length);
    if (msgtxt.length < 5) {
      iziToast.error({
        message: "Message must be 5 characters long.",
        position: "topCenter"
      });
    }
    else {
      axios.post(apipath + 'raise_ticket', ticketData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          var data = response.data;
          if (data.status === "false") {
            iziToast.error({
              message: data.message,
              position: "topCenter"
            });
          }
          else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
            navigate('/ticketlisting');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'mytickets',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        );
        setrecentData(response.data);
        if (response.data.status === "false") {
          localStorage.removeItem('token');
          navigate('/login');
        }
      }
      catch (error) {
        console.error('Error fetching orders data:', error);
        // if (localStorage.getItem('token')) {
        //   localStorage.removeItem('token');
        // }
        // navigate('/login');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }

  localStorage.setItem('ticketData', JSON.stringify(recentData.tickets));
  if (recentData.services.length > 0) {
    var services = recentData.services;
    var servoption = services.map((servs) => {
      return (
        <>
          <option value={servs.id}>{servs.name}</option>
        </>
      )
    })
  }
  else {
    servoption = "";
  }
  if (recentData.staff.length > 0) {
    var staff = recentData.staff;
    var staffoption = staff.map((staf) => {
      return (
        <>
          <option value={staf.id}>{staf.name}</option>
        </>
      )
    })
  }
  else {
    staffoption = "";
  }
  if (recentData.tickets.length > 0) {
    var rowdata = recentData.tickets.slice(0, 5);
    var rows = rowdata.map((ticket) => {
      return (
        <>
          <Link to={`/ticketdetail/${ticket.id}`}>
            <div className="ticket-list mb-2">
              <h4>{ticket.ticket_id} - {ticket.subject}</h4>
              <h5 className="pt-2">{ticket.status}</h5>
              <h6 className="pt-2">{ticket.date}</h6>
            </div>
          </Link>
        </>
      );
    });
  }
  else {
    rows = "No Tickets";
  }

  return (
    <>
      <Dashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <Sidebar activePage={window.location.pathname.split('/')[1]} />

        <div className="home-container">
          <div className="container pt-4 pb-3">
            <div className="row">
              <div className="col-lg-4">
                <div className="recent-ticket">
                  <div className="ticket-heading pb-2">
                    <h4>Your Recent Tickets</h4>
                  </div>
                  {rows}
                  {/* <div className="ticket-list">
                    <h4>#893858 - Document Uploading issue</h4>
                    <h5 className="pt-2">Answered</h5>
                    <h6 className="pt-2">2 Months ago</h6>
                  </div>
                  <div className="ticket-list mt-2">
                    <h4>#893858 - Document Uploading issue</h4>
                    <h5 className="pt-2">Answered</h5>
                    <h6 className="pt-2">2 Months ago</h6>
                  </div>
                  <div className="ticket-list mt-2">
                    <h4>#893858 - Document Uploading issue</h4>
                    <h5 className="pt-2">Answered</h5>
                    <h6 className="pt-2">2 Months ago</h6>
                  </div> */}
                  <div className="support-ticket pt-4">
                    <h4>Support</h4>
                    <Link className="support-link" to="/ticketlisting">
                      My Support Tickets
                    </Link>
                    <br></br>
                    <b>Email Us : </b><a href={`mailto:support@taxaj.com`} target="_blank">support@taxaj.com</a>
                    <br></br>
                    <b>Call Us : </b><a href="tel:8802912345">8802912345</a> &nbsp; <a href="tel:7091020202">7091020202</a>
                    <br></br>
                    <a href={'https://wa.me/8802912345'} target="_blank" rel="noopener noreferrer">
                      <i class="fa fa-whatsapp"></i> WhatsApp
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="ticket-information">
                  <div className="ticket-heading pb-2">
                    <h4>Ticket Information</h4>
                    <form className="ticket-infoform row mt-3" onSubmit={handleSubmit}>
                      <div className="mb-4 col-lg-4">
                        <label> Name </label>
                        <input type="text" onChange={handleChange} required name="name" className="form-control" placeholder="Name" />
                      </div>
                      <div className="mb-4 col-lg-4">
                        <label> Email </label>
                        <input type="email" onChange={handleChange} required name="email" className="form-control" placeholder="Email" />
                      </div>
                      <div className="mb-4 col-lg-4">
                        <label> Department </label>
                        <select className="form-select" onChange={handleChange} required name="department" aria-label="Default select example" >
                          <option value="" >Select Department</option>
                          <option value="Start Up" >Start Up</option>
                          <option value="Income Tax" >Income Tax</option>
                          <option value="All in One" >All in One</option>
                          <option value="GST Returns" >GST Returns</option>
                          <option value="Payroll Services" >Payroll Services</option>
                          <option value="Secretarial Filings" >Secretarial Filings</option>
                          <option value="TDS Returns" >TDS Returns</option>
                          <option value="Licenses/Regulatory Registrations" >Licenses/Regulatory Registrations</option>
                          <option value="Legal Drafting/Filings" >Legal Drafting/Filings</option>
                          <option value="Intellectual Property Rights" >Intellectual Property Rights</option>
                          <option value="Conversion" >Conversion</option>
                          <option value="Closure" >Closure</option>
                          <option value="Others" >Others</option>
                        </select>
                      </div>
                      <div className="mb-4 col-lg-4">
                        <label>Relationship Manager </label>
                        <select className="form-select" onChange={handleChange} required name="staff" aria-label="Default select example" >
                          <option value={""}>Select Staff</option>
                          {staffoption}
                          <option value="Unknown">Unknown</option>
                        </select>
                      </div>
                      <div className="mb-4 col-lg-4">
                        <label> Related Service </label>
                        <select className="form-select" onChange={handleChange} required name="service" aria-label="Default select example" >
                          <option value={""}>Select Service</option>
                          {servoption}
                        </select>
                      </div>
                      <div className="mb-4 col-lg-4">
                        <label> Priority </label>
                        <select className="form-select" onChange={handleChange} required name="priority" aria-label="Default select example" >
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                        </select>
                      </div>
                      <div className="mb-4 col-lg-4">
                        <label> Subject </label>
                        <input type="text" onChange={handleChange} required name="subject" className="form-control" placeholder="Document Uploading issue" />
                      </div>

                      <h4 className="pt-4"> Message </h4>
                      <div className="text-editor pb-4">
                        <div className="editor" id="editor-1">
                          <div className="toolbar">
                            <Link href="#" onClick={() => applystyle('undo')} data-command="undo" data-toggle="tooltip" data-placement="top" title="Undo" >
                              <i className="fa fa-undo"></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('redo')} data-command="redo" data-toggle="tooltip" data-placement="top" title="Redo" >
                              <i className="fa fa-redo "></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('removeFormat')} data-command="removeFormat" data-toggle="tooltip" data-placement="top" title="Clear format" >
                              <i className="fa fa-times "></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('bold')} data-command="bold" data-toggle="tooltip" data-placement="top" title="Bold" >
                              <i className="fa fa-bold"></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('italic')} data-command="italic" data-toggle="tooltip" data-placement="top" title="Italic" >
                              <i className="fa fa-italic"></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('underline')} data-command="underline" data-toggle="tooltip" data-placement="top" title="Underline" >
                              <i className="fa fa-underline"></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('strikeThrough')} data-command="strikeThrough" data-toggle="tooltip" data-placement="top" title="Stike through" >
                              <i className="fa fa-strikethrough"></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('justifyLeft')} data-command="justifyLeft" data-toggle="tooltip" data-placement="top" title="Left align" >
                              <i className="fa fa-align-left"></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('justifyCenter')} data-command="justifyCenter">
                              <i className="fa fa-align-center" data-toggle="tooltip" data-placement="top" title="Center align" ></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('justifyRight')} data-command="justifyRight" data-toggle="tooltip" data-placement="top" title="Right align" >
                              <i className="fa fa-align-right"></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('insertUnorderedList')} data-command="insertUnorderedList" data-toggle="tooltip" data-placement="top" title="Unordered list" >
                              <i className="fa fa-list-ul"></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('insertOrderedList')} data-command="insertOrderedList" data-toggle="tooltip" data-placement="top" title="Ordered list" >
                              <i className="fa fa-list-ol"></i>
                            </Link>
                            <Link href="#" onClick={() => applystyle('formatBlock', 'H1')} data-command="h1" data-toggle="tooltip" data-placement="top" title="H1" >
                              H1
                            </Link>
                            <Link href="#" onClick={() => applystyle('formatBlock', 'H2')} data-command="h2" data-toggle="tooltip" data-placement="top" title="H2" >
                              H2
                            </Link>
                            <Link href="#" onClick={() => applystyle('formatBlock', 'H3')} data-command="h3" data-toggle="tooltip" data-placement="top" title="H3" >
                              H3
                            </Link>
                            <Link href="#" onClick={() => applystyle('formatBlock', 'H4')} data-command="h4" data-toggle="tooltip" data-placement="top" title="H4" >
                              H4
                            </Link>
                            <Link href="#" onClick={() => applystyle('formatBlock', 'H5')} data-command="h5" data-toggle="tooltip" data-placement="top" title="H5" >
                              H5
                            </Link>
                            <Link href="#" onClick={() => applystyle('formatBlock', 'H6')} data-command="h6" data-toggle="tooltip" data-placement="top" title="H6" >
                              H6
                            </Link>
                            <Link href="#" onClick={() => applystyle('formatBlock', 'P')} data-command="p" data-toggle="tooltip" data-placement="top" title="Paragraph" >
                              P
                            </Link>
                          </div>
                          <div id="editor" onInput={handleMessage} className="editorAria" contentEditable="true">

                          </div>
                        </div>
                      </div>
                      <h4>Attachment</h4>
                      <div className=" col-lg-8 attachment-file">
                        <input type="file" name="attachment" onChange={handleImageChange} multiple className="form-control" placeholder="Akash Rawat" />
                        <small>
                          Allowed file extensions : .jpg, .jpeg, .gif, .png,
                          .pdf, .doc, .docx
                        </small>
                      </div>
                      <div className="sub-cancel-btns pt-4">
                        <button className="tick-submit me-3">Submit</button>
                        {/* <button className="tick-cancel">Cancel</button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Raiseticket;
