import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Dashboard = () => {
  const { apipath, logout } = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  // const { customerId } = useParams();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [dashData, setdashData] = useState();
  const [sdata, setsdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({ order_name: '', date: '' });
  const today = new Date().toISOString().split('T')[0];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...sdata];
    Object.keys(filters).forEach(key => {
      const filterValue = filters[key].toLowerCase();
      if (filterValue) {
        filteredItems = filteredItems.filter(item =>
          item[key].toLowerCase().includes(filterValue)
        );
      }
    });
    setFilteredData(filteredItems);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'dashboard',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        );
        if (response.data.status === "false") {
          localStorage.removeItem('token');
          navigate('/login');
        }
        setdashData(response.data);
        setsdata(response.data.services);
        setFilteredData(response.data.services);
      }
      catch (error) {
        console.error('Error fetching dashboard data:', error);
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
        navigate('/login');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);
  console.log(dashData);


  if (loading) {
    return <div>Loading...</div>;
  }
  const scolumns = [
    { name: 'ID', selector: row => row.id, sortable: true, width: '80px' },
    { name: 'Service Name', selector: row => row.order_name, sortable: true, width: '250px' },
    { name: 'Date (yyyy-mm-dd)', selector: row => row.date, sortable: true },
    { name: 'Timeline', selector: row => row.sla, sortable: true },
    { name: 'Registration Status', selector: row => row.status, sortable: true },
    { name: 'Payment Status', selector: row => row.payment_status, sortable: true },
    {
      name: 'Action', cell: row => (
        <Link to={`/orderdetail/${row.id}`}><i class="bi bi-eye viewbtn"></i></Link>
      ), width: '100px'
    },
    // Add more columns as needed
  ];
  localStorage.setItem('serviceData', JSON.stringify(sdata));
  // if (serviceData.length > 0) {
  //   var rowdata = serviceData;
  //   var rows = rowdata.map((serv) => {
  //     return (
  //       <>
  //         <tr>
  //           <td>{serv.service_id}</td>
  //           <td>{serv.order_name}</td>
  //           <td>{serv.date}</td>
  //           <td>{serv.sla} days</td>
  //           <td>{serv.status}</td>
  //           <td>{serv.payment_status}</td>
  //           <td></td>
  //           <td><Link to={`/orderdetail/${serv.id}`}><i class="bi bi-eye viewbtn"></i></Link></td>
  //         </tr>
  //       </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No services.";
  // }
  return (
    <>
      <Dashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <Sidebar activePage={window.location.pathname.split('/')[1]} />

        <div className="home-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="dash-infobox">
                  <div className="dash-head">
                    <h5>Services Purchased</h5>
                    <img src="images/dash1.png" width={30} height={30} alt="" />
                  </div>
                  <h4>{dashData.services_purchased}</h4>
                  <Link to={'/myorders'} className="dash-link">View</Link>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="dash-infobox">
                  <div className="dash-head">
                    <h5>Order In Progress</h5>
                    <img src="images/dash2.png" width={30} height={30} alt="" />
                  </div>
                  <h4>{dashData.progress_orders}</h4>
                  <Link to={'/myorders'} className="dash-link">View</Link>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="dash-infobox">
                  <div className="dash-head">
                    <h5>Pending Order</h5>
                    <img to={'/myorders'} src="images/dash2.png" width={30} height={30} alt="" />
                  </div>
                  <h4>{dashData.pending_orders}</h4>
                  <Link to={'/myorders'} className="dash-link">View</Link>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="dash-infobox">
                  <div className="dash-head">
                    <h5>Complete Orders</h5>
                    <img src="images/dash2.png" width={30} height={30} alt="" />
                  </div>
                  <h4>{dashData.complete_orders}</h4>
                  <Link to={'/myorders'} className="dash-link">View</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>My Services</h5>
                {/* <div class="new-filter">
                  <div class="input-filterbox">
                    <input
                      class="search me-2"
                      type="search"
                      placeholder="Search..."
                    />
                    <input type="date" className="form-control" />
                  </div>
                </div> */}
                <div>
                  <div className="row m-0" id="table_detail">
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="order_name"
                        placeholder="Search by name"
                        value={filters.order_name}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="date"
                        name="date"
                        max={today}
                        placeholder="Search by Date"
                        value={filters.date}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-2 p-1">
                      <button onClick={applyFilters}>Search</button>
                    </div>
                  </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10,25,50,100]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>Service ID</th>
                      <th>Service Name</th>
                      <th>Purchased Date</th>
                      <th>Timeline</th>
                      <th>Registration Status</th>
                      <th>Payment Status</th>
                      <th>Documents</th>
                      <th>Action</th>
                    </tr>
                    {rows}
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
