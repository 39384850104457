import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import iziToast from "izitoast";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Adminorders = () => {
    const { apipath, adminLogout } = useAuth();
    const [show, setShow] = useState(true);
    const navigate = useNavigate();
    const admintoken = localStorage.getItem('admintoken');
    const [loading, setLoading] = useState(true);
    const [orderData, setorderData] = useState([]);
    const [staffData, setstaffData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState('');
    const today = new Date().toISOString().split('T')[0];

    const handleFilterChange = (e) => {
        const { value } = e.target;
        setFilters(value);
    };
    const applyFilters = () => {
        console.log(filters);
        let filteredItems = [...orderData];
        const filterValue = filters.toLowerCase(); if (filterValue) {
            filteredItems = filteredItems.filter(item => {
                return Object.values(item).some(val =>
                    typeof val === 'string' && val.toLowerCase().includes(filterValue)
                );
            });
        }
        setFilteredData(filteredItems);
    };
    useEffect(() => {
        applyFilters();
    }, [filters]);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.post(
                    apipath + 'adminorders',
                    {},
                    {
                        headers: {
                            Authorization: 'Bearer ' + admintoken
                        }
                    }
                );
                setorderData(response.data.orders);
                setFilteredData(response.data.orders);
                setstaffData(response.data.staff);
                if (response.data.status === "false") {
                    localStorage.removeItem('admintoken');
                    navigate('/adminlogin');
                }
            }
            catch (error) {
                console.error('Error fetching orders data:', error);
                if (localStorage.getItem('admintoken')) {
                    localStorage.removeItem('admintoken');
                }
                navigate('/adminlogin');

            } finally {
                setLoading(false);
            }
        }
        checkAuthentication();
    }, []);

    const [statusData, setstatusData] = useState({
        status: '',
        order_id: '',
    });
    const handleStatus = (e) => {
        var oid = e.target.getAttribute('data-orderid');
        setstatusData({ ...statusData, ['status']: e.target.value, ['order_id']: oid });
    }
    useEffect(() => {
        if (statusData.status !== '') {
            axios.post(apipath + 'update_order_status', statusData,
                {
                    headers: {
                        Authorization: 'Bearer ' + admintoken
                    }
                })
                .then((response) => {
                    var data = response.data;
                    if (data.status === "false") {
                        iziToast.error({
                            message: data.message,
                            position: "topCenter"
                        });
                    }
                    else if (data.status === "true") {
                        iziToast.success({
                            message: data.message,
                            position: "topCenter"
                        });
                    }
                })
                .catch((error) => {
                    iziToast.error({
                        message: error,
                        position: "topCenter"
                    });
                });
        }
    }, [statusData]);

    const [assignData, setassignData] = useState({
        id: '',
        order_id: '',
    });
    const handleAssignto = (e) => {
        var oid = e.target.getAttribute('data-orderid');
        setassignData({ ...assignData, ['id']: e.target.value, ['order_id']: oid });
    }
    useEffect(() => {
        if (assignData.id !== '') {
            axios.post(apipath + 'assignorder', assignData,
                {
                    headers: {
                        Authorization: 'Bearer ' + admintoken
                    }
                })
                .then((response) => {
                    var data = response.data;
                    if (data.status === "false") {
                        iziToast.error({
                            message: data.message,
                            position: "topCenter"
                        });
                    }
                    else if (data.status === "true") {
                        iziToast.success({
                            message: data.message,
                            position: "topCenter"
                        });
                    }
                })
                .catch((error) => {
                    iziToast.error({
                        message: error,
                        position: "topCenter"
                    });
                });
        }
    }, [assignData]);

    if (loading) {
        return <div>Loading...</div>;
    }

    localStorage.setItem('orderData', JSON.stringify(orderData.orders));

    const scolumns = [
        { name: 'Order ID', selector: row => row.order_id, sortable: true, width: '100px' },
        { name: 'User Name', selector: row => row.user_name, sortable: true, width: '150px' },
        { name: 'Order Name', selector: row => row.order_name, sortable: true, width: '150px' },
        { name: 'Payment ID', selector: row => row.payment_id, sortable: true, width: '170px' },
        { name: 'Price', selector: row => row.price, sortable: true, width: '100px' },
        { name: 'Timeline', selector: row => row.sla, sortable: true, width: '100px' },
        {
            name: 'Status', selector: row => (
                <>
                    <select class="status_select" style={{"width":"100%"}} onChange={handleStatus} data-orderid={row.id}>
                        <option selected={row.status === "Pending"} value={'Pending'}>Pending</option>
                        {
                            row.order_process.map((procs) => {
                                return (
                                    <>
                                        <option selected={row.status === procs} value={procs}>{procs}</option>
                                    </>
                                );
                            })
                        }
                    </select>
                </>
            ), sortable: true, width: '160px'
        },
        { name: 'Payment', selector: row => row.payment_status, sortable: true, width: '100px' },
        {
            name: 'Assigned To', selector: row => (
                <>
                    <select onChange={handleAssignto} style={{"width":"100%"}} data-orderid={row.id}>
                        <option value={''}>Select Staff</option>
                        {
                            staffData.map((staf) => {
                                return (
                                    <>
                                        <option selected={row.assigned_to === staf.id} value={staf.id}>{staf.name}</option>
                                    </>
                                );
                            })
                        }
                    </select>
                </>
            ), sortable: true, width: '150px'
        },
        { name: 'Order Date', selector: row => row.date, sortable: true, width: '150px' },
        // { name: 'Related Service', selector: row => row.service_name, sortable: true, width: '150px' },
        {
            name: 'Action', cell: row => (
                <Link to={`/adminorderdetail/${row.id}`}><i class="bi bi-eye viewbtn"></i></Link>
            )
        },
        // Add more columns as needed
    ];
    // if (orderData.orders.length > 0) {
    //     var rowdata = orderData.orders;
    //     var staffdata = orderData.staff;
    //     var selprocess = "";
    //     var selstaff = "";
    //     var rows = rowdata.map((ordr) => {

    //         var processdata = ordr.order_process;
    //         if (processdata.length > 0) {
    //             selprocess = processdata.map((procs) => {
    //                 return (
    //                     <>
    //                         <option selected={ordr.status === procs} value={procs}>{procs}</option>
    //                     </>
    //                 );
    //             });
    //         }
    //         if (staffdata.length > 0) {
    //             selstaff = staffdata.map((staf) => {
    //                 return (
    //                     <>
    //                         <option selected={ordr.assigned_to === staf.id} value={staf.id}>{staf.name}</option>
    //                     </>
    //                 );
    //             });
    //         }
    //         return (
    //             <>
    //                 <tr>
    //                     <td>{ordr.order_id}</td>
    //                     <td>{ordr.user_name}</td>
    //                     <td>{(ordr.order_name.length > 30) ? ordr.order_name.substr(0, 30) + '...' : ordr.order_name.substr(0, 30)}</td>
    //                     <td>{ordr.payment_id}</td>
    //                     <td>Rs.{ordr.price}</td>
    //                     <td>{ordr.sla} days</td>
    //                     <td>
    //                         <select class="status_select" onChange={handleStatus} data-orderid={ordr.id}>
    //                             <option selected={ordr.status === "Pending"} value={'Pending'}>Pending</option>
    //                             {selprocess}
    //                         </select>
    //                     </td>
    //                     <td>{ordr.payment_status}</td>
    //                     <td>
    //                         <select onChange={handleAssignto} data-orderid={ordr.id}>
    //                             <option value={''}>Select Staff</option>
    //                             {selstaff}
    //                         </select>
    //                     </td>
    //                     <td>{ordr.date}</td>
    //                     <td>Pending</td>
    //                     <td>
    //                         <Link to={`/adminorderdetail/${ordr.id}`}><i class="bi bi-eye viewbtn"></i></Link> &nbsp;
    //                         {/* <i class="bi bi-trash3"></i> &nbsp; */}
    //                         {/* <i class="bi bi-download"></i> &nbsp; */}
    //                     </td>
    //                 </tr>
    //             </>
    //         );
    //     });
    // }
    // else {
    //     rows = "No orders.";
    // }
    return (
        <>
            <Admindashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <aside className={`sidebar ${show ? "show1" : null}`}>
                    <nav className="nav1">
                        <div>
                            <div className="nav-list">
                                <Link
                                    to={`/admindashboard`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-grid-1x2"></i>
                                    <span className="nav-link-name">Dashboard</span>
                                </Link>
                                <Link
                                    to={`/admincustomers`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                    <span className="nav-link-name">Customers</span>
                                </Link>
                                <Link
                                    to={`/adminstaff`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Staff</span>
                                </Link>
                                <Link
                                    to={`/adminorders`}
                                    className="nav-linksidebar active-bar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Orders</span>
                                </Link>
                                <Link
                                    to={`/adminchat`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-chat-left-text"></i>
                                    <span className="nav-link-name">Chats</span>
                                </Link>
                                <Link
                                    to={`/adminticket`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-ticket-detailed"></i>
                                    <span className="nav-link-name">Tickets</span>
                                </Link>
                                <Link
                                    to={`/adminservice`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Services</span>
                                </Link>
                                {/* <Link
                                    to={`/admintask`}
                                    className="nav-linksidebar active-bar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Tasks</span>
                                </Link> */}
                                <hr />
                                {/* <Link
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-webcam"></i>
                                    <span className="nav-link-name">Profile</span>
                                </Link> */}
                                <Link onClick={adminLogout}
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-gear"></i>
                                    <span className="nav-link-name">Logout</span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </aside>

                <div className="home-container">

                    <div className="container pt-4">
                        <div className="row">
                            <div class="col-lg-12 table-column">
                                <div class="row justify-content-between">
                                    <div class="col"><h5>Orders</h5></div>
                                </div>
                                <div>
                                    <div className="row m-0" id="table_detail">
                                        <div className="col-lg-2 col-md-3 col-sm-12 p-1">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                placeholder="Search"
                                                value={filters.order_name}
                                                onChange={handleFilterChange}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-12 p-1">
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="date"
                                                placeholder="Search by Date"
                                                value={filters.date}
                                                max={today}
                                                onChange={handleFilterChange}
                                            />
                                        </div>
                                        <div className="col-lg-8 col-md-6 col-sm-12 mb-2 p-1">
                                            <button className="btn btn-primary shadow-none" onClick={applyFilters}>Search</button>
                                        <Link class="d-flex float-end" to={`/adminaddorder`}>
                                            <button className="btn btn-sm btn-primary shadow-none"><i class="bi bi-plus"></i> Add New</button>
                                        </Link>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={scolumns}
                                        data={filteredData}
                                        pagination
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    />
                                    {/* <table id="table_detail">
                                        <tr>
                                            <th>Order ID</th>
                                            <th>User Name</th>
                                            <th>Order Name</th>
                                            <th>Payment ID</th>
                                            <th>Price</th>
                                            <th>Timeline</th>
                                            <th>Status</th>
                                            <th>Payment</th>
                                            <th>Assigned To</th>
                                            <th>Order Date</th>
                                            <th>Documents Upload</th>
                                            <th>Action</th>
                                        </tr>
                                        {rows}
                                    </table> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </>
    )
}

export default Adminorders