import React from 'react'
import { Link } from "react-router-dom";
const Staffregister = () => {
  return (
    <>
     <div className="container-fluid register-container">
        <div className="row main-register-row">
          <div className="col-lg-4">
            <div className="register-box-left">
              <img src="images/reg-icon.png" alt="" />
            </div>
          </div>
          <div className="col-lg-8 register-column">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                <div className="register-box-right">
              <h4>Register Your Account</h4>
              <h6 className="pt-3 pb-4">
                {" "}
                Welcome to <span>TaxAJ</span>{" "}
              </h6>

              <form className="register-form">
                <div className="mb-5">
                    <input type="text" className="form-control" placeholder="Full Name" />
                </div>
                <div className="mb-5">
                    <input type="text" className="form-control" placeholder="Email Address" />
                </div>
                <div className="mb-5">
                    <input type="text" className="form-control" placeholder="Phone Number" />
                </div>
                <div className="mb-5">
                    <input type="text" className="form-control" placeholder="Role" />
                </div>
                <div className="mb-5">
                    <input type="password" className="form-control" placeholder="Password" />
                    <span toggle="#password-field" className="bi bi-eye-fill field-icon toggle-password"></span>
                </div>
                <button class="register-btn mb-4">
  <span>Create Account</span>
</button>
<small>Already have an account? <Link to="/login" >Log in</Link> </small>
              </form>
            </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Staffregister