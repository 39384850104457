import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Staffnavbar from "./Staffnavbar";
import { useAuth } from '../AuthContext';
const Staffaddorder = () => {
    const { apipath, staffLogout } = useAuth();
    const [show, setShow] = useState(true);
    const navigate = useNavigate();
    const [customers, setcustomers] = useState([]);
    const [services, setservices] = useState([]);
    const [loading, setLoading] = useState(true);
    const stafftoken = localStorage.getItem('stafftoken');
    const [userData, setUserData] = useState({
        customer: '',
        service: '',
    });

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(apipath + 'addorder', userData,
            {
                headers: {
                    Authorization: 'Bearer ' + stafftoken
                }
            })
            .then((response) => {
                var data = response.data;
                if (data.status === "false") {
                    iziToast.error({
                        message: data.message,
                        position: "topCenter"
                    });
                }
                else if (data.status === "true") {
                    iziToast.success({
                        message: data.message,
                        position: "topCenter"
                    });
                    navigate('/stafftask');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const checkAuthentication = async () => {
        try {
            const response = await axios.post(
                apipath + 'getcustomerservice',
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + stafftoken
                    }
                }
            );
            setcustomers(response.data.customers);
            setservices(response.data.services);
            if (response.data.status === "false") {
                localStorage.removeItem('stafftoken');
                navigate('/stafflogin');
            }
        }
        catch (error) {
            console.error('Error fetching orders data:', error);
            // if (localStorage.getItem('token')) {
            //   localStorage.removeItem('token');
            // }
            // navigate('/login');

        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        checkAuthentication();
    });
    if (loading) {
        return 'loading...';
    }

    if (services.length > 0) {
        var servoption = services.map((servs) => {
            return (
                <>
                    <option value={servs.id}>{servs.name}</option>
                </>
            )
        })
    }
    else {
        servoption = "";
    }
    if (customers.length > 0) {
        var useroption = customers.map((usr) => {
            return (
                <>
                    <option value={usr.id}>{usr.name}</option>
                </>
            )
        })
    }
    else {
        useroption = "";
    }

    return (
        <>
            <Staffnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <aside className={`sidebar ${show ? "show1" : null}`}>
                    <nav className="nav1">
                        <div>
                            <div className="nav-list">
                                <Link
                                    to={`/staffcustomer`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                    <span className="nav-link-name">Customers</span>
                                </Link>
                                <Link
                                    to={`/staffchat`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-chat-left-text"></i>
                                    <span className="nav-link-name">Chats</span>
                                </Link>
                                <Link
                                    to={`/staffticket`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-ticket-detailed"></i>
                                    <span className="nav-link-name">Tickets</span>
                                </Link>
                                <Link
                                    to={`/stafftask`}
                                    className="nav-linksidebar active-bar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Tasks</span>
                                </Link>
                                <hr />
                                {/* <Link
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-webcam"></i>
                                    <span className="nav-link-name">Profile</span>
                                </Link> */}
                                <Link onClick={staffLogout}
                                    to={`/customer-profile/`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-gear"></i>
                                    <span className="nav-link-name">Logout</span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </aside>

                <div className="home-container">

                    <div className="container pt-4">
                        <div className="row">
                            <div class="col-lg-12 table-column">
                                <h5>Add Order</h5>
                                <div>

                                    <form className="register-form" onSubmit={handleSubmit}>
                                        <div className="mb-5">
                                            <select className="form-select" onChange={handleChange} required name="customer" aria-label="Default select example" >
                                                <option value={""}>Select Customer</option>
                                                {useroption}
                                            </select>
                                            {/* <input type="text" name="customer" required className="form-control" onChange={handleChange} placeholder="Full Name" /> */}
                                        </div>
                                        <div className="mb-5">
                                            <select className="form-select" onChange={handleChange} required name="service" aria-label="Default select example" >
                                                <option value={""}>Select Service</option>
                                                {servoption}
                                            </select>
                                            {/* <input type="email" name="service" required className="form-control" onChange={handleChange} placeholder="Email Address" /> */}
                                        </div>
                                        <button class="register-btn mb-4">
                                            <span>Add Order</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Staffaddorder