import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Adminstaff = () => {
  const { apipath, adminLogout } = useAuth();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const admintoken = localStorage.getItem('admintoken');
  const [loading, setLoading] = useState(true);
  const [dashData, setdashData] = useState();
  const [staffData, setstaffData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState('');

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilters(value);
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...staffData];
    const filterValue = filters.toLowerCase(); if (filterValue) {
      filteredItems = filteredItems.filter(item => {
        return Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(filterValue)
        );
      });
    }
    setFilteredData(filteredItems);
  };
  useEffect(() => {
    applyFilters();
  }, [filters]);
  const checkAuthentication = async () => {
    try {
      const response = await axios.post(
        apipath + 'staff',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        }
      );
      setdashData(response.data);
      setstaffData(response.data.staff);
      setFilteredData(response.data.staff);
      if (response.data.status === "false") {
        localStorage.removeItem('admintoken');
        navigate('/adminlogin');
      }
    }
    catch (error) {
      console.error('Error fetching dashboard data:', error);
      if (localStorage.getItem('admintoken')) {
        localStorage.removeItem('admintoken');
      }
      navigate('/adminlogin');

    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    checkAuthentication();
  }, []);
  const deletestaff = (sid) => {
    const sdata = {
      id: sid,
    }
    try {
      axios.post(apipath + 'deletestaff', sdata,
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        }
      )
        .then((response) => {
          var data = response.data;
          console.log(data);
          if (data.status === "false") {
            iziToast.error({
              message: data.message,
              position: "topCenter"
            });
          }
          else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
            checkAuthentication();
          }
        });
    }
    catch (error) {
      iziToast.error({
        message: "Something wrong please try again.",
        position: "topCenter"
      });
      console.error('Error fetching dashboard data:', error);

    }
  }

  console.log(dashData);


  if (loading) {
    return <div>Loading...</div>;
  }
  localStorage.setItem('staffData', JSON.stringify(dashData.staff));
  
  const scolumns = [
    { name: 'Staff ID', selector: row => row.id, sortable: true, width: '100px' },
    { name: 'Name', selector: row => row.name, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'Phone Number', selector: row => row.phone, sortable: true },
    { name: 'Service Assigned', selector: row => row.assigned, sortable: true },
    { name: 'Service Completed', selector: row => row.completed, sortable: true },
    { name: 'Department', selector: row => row.department, sortable: true },
    {
      name: 'Action', cell: row => (
        <>
          <Link to={`/admineditstaff/${row.id}`}><i class="bi bi-pencil viewbtn"></i> &nbsp;{" "}</Link>
          <i class="bi bi-trash3 viewbtn" onClick={() => deletestaff(row.id)}></i>{" "}
        </>
      )
    },
    // Add more columns as needed
  ];
  // if (dashData.staff.length > 0) {
  //   var rowdata = dashData.staff;
  //   var rows = rowdata.map((customer) => {
  //     return (
  //       <>
  //         <tr>
  //           <td>{customer.id}</td>
  //           <td>{customer.name}</td>
  //           <td>{customer.email}</td>
  //           <td>{customer.phone}</td>
  //           <td>{customer.service}</td>
  //           <td>{customer.payment_status}</td>
  //           <td>{customer.department}</td>
  //           <td>
  //             {" "}
  //             <Link to={`/admineditstaff/${customer.id}`}><i class="bi bi-pencil viewbtn"></i></Link> &nbsp;{" "}
  //             <i class="bi bi-trash3 ms-1 viewbtn" onClick={() => deletestaff(customer.id)}></i>{" "}
  //           </td>
  //         </tr>
  //       </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No staff.";
  // }
  return (
    <>
      <Admindashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/admindashboard`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-grid-1x2"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link
                  to={`/admincustomers`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/adminstaff`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Staff</span>
                </Link>
                <Link
                  to={`/adminorders`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Orders</span>
                </Link>
                <Link
                  to={`/adminchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/adminticket`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/adminservice`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Services</span>
                </Link>
                {/* <Link
                  to={`/admintask`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link> */}
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={adminLogout}
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">

          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <div class="row justify-content-between">
                  <div class="col"><h5>Staff</h5></div>
                  <div class="col d-flex justify-content-end">
                  </div>
                </div>
                <div>
                    <div className="row m-0" id="table_detail">
                      <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Search"
                          value={filters.order_name}
                          onChange={handleFilterChange}
                        />
                      </div>
                      <div className="col-lg-9 col-md-6 col-sm-12 mb-2 p-1">
                        <button class="btn btn-primary shadow-none" onClick={applyFilters}>Search</button>
                        <Link class="d-flex float-end" to={`/adminaddstaff`}>
                          <button className="btn btn-sm btn-primary shadow-none"><i class="bi bi-plus"></i> Add New</button>
                        </Link>
                      </div>
                    </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>Staff ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Service Assigned</th>
                      <th>Service Completed</th>
                      <th>Department</th>
                      <th>Action</th>
                    </tr>
                    {rows}
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Adminstaff