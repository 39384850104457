import React, { useState, useEffect } from "react";
import iziToast from "izitoast";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
const Adminorderprogress = () => {
  const { apipath, filepath, adminLogout } = useAuth();
  const [show, setShow] = useState(true);
  const { customerId } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const admintoken = localStorage.getItem('admintoken');
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(
        apipath + 'adminorder_detail/' + id,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        }
      );
      setOrder(response.data.order);
      console.log(response.data);
      if (response.data.status === "false" && response.data.message === "Unauthentic access") {
        localStorage.removeItem('token');
        navigate('/login');
      }
      if (response.data.status === "false" && response.data.message !== "Unauthentic access") {
        iziToast.error({
          message: response.data.message,
          position: "topCenter"
        });
      }
    }
    catch (error) {
      console.error('Error fetching orders data:', error);
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }
      navigate('/login');

    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    checkAuthentication();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (order.documents.length > 0) {
    var docData = order.documents;
    var docs = docData.map((doc) => {
      if (doc.field_type === "file") {
        var file = filepath + "user" + order.user_id + "/" + doc.field_value;
        var field = (
          <>
            {(doc.field_value !== "") ? <a href={file} download ><button>Download</button></a> : ""}
          </>
        );
      }
      if (doc.field_type === "text") {
        var field = (
          <>
            <p>{doc.field_value}</p>

          </>
        );
      }
      return (
        <>

          <div className="order-innerbox">
            <div className="order-status-detail">
              <div className="col-9 me-4">
                <h4>{(doc.field_type === "file") ? "Document Upload" : "Text Upload"} {(doc.required === "true") ? "*" : ""}</h4>
                <h6>
                  {doc.doc_name ?? ""}
                </h6>
              </div>
              <div className="pending col-3">
                <i className="bi bi-check-circle-fill"></i>
                <h4 className="pt-1">{doc.status}</h4>
              </div>
            </div>
            <div className="pending-box">
              <small>{doc.upload_date}</small>
              {field}
            </div>
          </div>
          <hr />
        </>
      );
    });
  }
  else {
    docs = "";
  }
  if (order.order_process.length > 0) {
    var order_status = order.status;
    var processData = order.order_process;
    var done = "true";
    var processes = processData.map((process) => {
      if (order_status === process || (done === "true" && order_status !== "Pending")) {
        var clas = "StepProgress-item is-done";
        if (order_status === process) {
          done = "false";
        }
      }
      else {
        clas = "StepProgress-item current";
      }
      return (
        <>
          <li className={clas}>
            <strong>{process}</strong>
          </li>
        </>
      );
    });
  }
  else {
    processes = "";
  }
  return (
    <>
      <Admindashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/admindashboard`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-grid-1x2"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link
                  to={`/admincustomers`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/adminstaff`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Staff</span>
                </Link>
                <Link
                  to={`/adminorders`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Orders</span>
                </Link>
                <Link
                  to={`/adminchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/adminticket`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/adminservice`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Services</span>
                </Link>
                {/* <Link
                  to={`/admintask`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link> */}
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={adminLogout}
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">
          <div className="container pt-4 pb-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="order-status">
                  <h5>
                    {" "}
                    Service - <b>{order.order_name}</b>{" "}
                  </h5>
                  <hr />
                  <div className="order-innerbox">
                    <div className="order-status-detail">
                      <div className="col-9 me-4">
                        <h4>Payment Completed</h4>
                        <h6>
                          Registration fee for {order.order_name} has been
                          paid
                        </h6>
                      </div>
                      <div className="col-3">
                        <i className="bi bi-check-circle-fill"></i>
                        <h4 className="pt-1">Confirmed</h4>
                      </div>
                    </div>
                    <small>{order.fulldate}</small>
                  </div>
                  <hr />
                  <div className="order-innerbox">
                    <div className="order-status-detail">
                      <div className="col-9 me-4">
                        <h4>Account Logged in</h4>
                        <h6>
                          Registration fee for {order.order_name} has been
                          paid
                        </h6>
                      </div>
                      <div className="col-3">
                        <i className="bi bi-check-circle-fill"></i>
                        <h4 className="pt-1">Confirmed</h4>
                      </div>
                    </div>
                    <small>{order.fulldate}</small>
                  </div>
                  <hr />
                  {docs}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="order-status">
                  <div className="chat-head">
                    <h5>Order Status</h5>
                    <img src="/images/chat.png" style={{'cursor':'pointer'}} width={40} height={40} alt="" onClick={()=>{window.location.href=`/adminchat/${order.id}`}} />
                  </div>
                  <hr />
                  <div className="wrapper">
                    <ul className="StepProgress">
                      <li className="StepProgress-item is-done">
                        <strong>Logged In</strong>
                      </li>
                      <li className="StepProgress-item is-done">
                        <strong>Payment Completed</strong>
                        Registration fee for {order.order_name} has been paid
                      </li>
                      {processes}

                      {/* <li className="StepProgress-item current">
                        <strong>Upload Documents</strong>
                        Please upload the pending documents required for this {order.order_name} Service.
                      </li>
                      <li className="StepProgress-item current">
                        <strong>Documents Collected</strong>
                      </li>
                      <li className="StepProgress-item current">
                        <strong>Document Validation</strong>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Adminorderprogress