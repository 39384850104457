import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Admincustomers = () => {
  const { apipath, adminLogout } = useAuth();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const admintoken = localStorage.getItem('admintoken');
  const [loading, setLoading] = useState(true);
  const [dashData, setdashData] = useState();
  const [customerData, setcustomerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState('');

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilters(value);
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...customerData];
    const filterValue = filters.toLowerCase(); if (filterValue) {
      filteredItems = filteredItems.filter(item => {
        return Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(filterValue)
        );
      });
    }
    setFilteredData(filteredItems);
  };
  useEffect(() => {
    applyFilters();
  }, [filters]);

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(
        apipath + 'customers',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        }
      );
      setdashData(response.data);
      setcustomerData(response.data.customers);
      setFilteredData(response.data.customers);
      if (response.data.status === "false") {
        localStorage.removeItem('admintoken');
        navigate('/login');
      }
    }
    catch (error) {
      console.error('Error fetching dashboard data:', error);
      if (localStorage.getItem('admintoken')) {
        localStorage.removeItem('admintoken');
      }
      navigate('/login');

    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    checkAuthentication();
  }, []);
  const handleExport = () => {

    try {
      axios.post(apipath + 'exportCustomers', {},
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        }
      )
        .then((response) => {
          var data = response.data;
          // console.log(data);
          if (data.status === "false") {
            iziToast.error({
              message: data.message,
              position: "topCenter"
            });
          }
          else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
            // window.open(response.data.path, '_blank');
            const link = document.createElement('a');
            link.href = data.path;
            link.setAttribute('download', 'customers.xlsx'); // Set the filename
            document.body.appendChild(link);

            // Trigger the download
            link.click();
            setTimeout(() => {
              document.body.removeChild(link);
            }, 1000);
          }
        });
    }
    catch (error) {
      iziToast.error({
        message: "Something wrong please try again.",
        position: "topCenter"
      });
      console.error('Error fetching dashboard data:', error);

    }
  }

  const deletecustomer = (sid) => {
    const sdata = {
      id: sid,
    }
    try {
      axios.post(apipath + 'deletestaff', sdata,
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        }
      )
        .then((response) => {
          var data = response.data;
          console.log(data);
          if (data.status === "false") {
            iziToast.error({
              message: data.message,
              position: "topCenter"
            });
          }
          else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
            checkAuthentication();
          }
        });
    }
    catch (error) {
      iziToast.error({
        message: "Something wrong please try again.",
        position: "topCenter"
      });
      console.error('Error fetching dashboard data:', error);

    }
  }
  console.log(dashData);


  if (loading) {
    return <div>Loading...</div>;
  }
  localStorage.setItem('customerData', JSON.stringify(dashData.customers));

  const scolumns = [
    { name: 'Customer ID', selector: row => row.id, sortable: true, width: '150px' },
    { name: 'Name', selector: row => row.name, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'Phone Number', selector: row => row.phone, sortable: true },
    { name: 'Service', selector: row => row.service, sortable: true },
    {
      name: 'Action', cell: row => (
        <>
          <Link to={`/admineditcustomer/${row.id}`}><i class="bi bi-pencil viewbtn"></i> &nbsp;{" "}</Link>
          <i class="bi bi-trash3 viewbtn" onClick={() => deletecustomer(row.id)}></i>{" "}
        </>
      )
    },
    // Add more columns as needed
  ];
  // if (dashData.customers.length > 0) {
  //   var rowdata = dashData.customers;
  //   var rows = rowdata.map((customer) => {
  //     return (
  //       <>
  //         <tr>
  //           <td>{customer.id}</td>
  //           <td>{customer.name}</td>
  //           <td>{customer.email}</td>
  //           <td>{customer.phone}</td>
  //           <td>{customer.company_name}</td>
  //           <td>{customer.service}</td>
  //           <td>{customer.payment_status}</td>
  //           <td>{customer.documents}</td>
  //           <td>
  //             {" "}
  //             <Link to={`/admineditcustomer/${customer.id}`}><i class="bi bi-pencil viewbtn"></i> &nbsp;{" "}</Link>
  //             <i class="bi bi-trash3 viewbtn" onClick={() => deletecustomer(customer.id)}></i>{" "}
  //           </td>
  //         </tr>
  //       </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No customers.";
  // }
  return (
    <>
      <Admindashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/admindashboard`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-grid-1x2"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link
                  to={`/admincustomers`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/adminstaff`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Staff</span>
                </Link>
                <Link
                  to={`/adminorders`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Orders</span>
                </Link>
                <Link
                  to={`/adminchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/adminticket`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/adminservice`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Services</span>
                </Link>
                {/* <Link
                  to={`/admintask`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link> */}
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={adminLogout}
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">

          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>Customers</h5>
                <div class="col">
                    <div className="row m-0" id="table_detail">
                      <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Search"
                          value={filters.order_name}
                          onChange={handleFilterChange}
                        />
                      </div>
                      <div className="col-lg-9 col-md-6 col-sm-12 mb-2 p-1">
                        <button class="btn btn-primary shadow-none" onClick={applyFilters}>Search</button>
                        <button id="exportbtn" className="btn btn-sm btn-primary shadow-none float-end" onClick={handleExport}>Export</button>
                      </div>
                    </div>
                </div>

                <div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>Customer ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Company Name</th>
                      <th>Service</th>
                      <th>Payment Status</th>
                      <th>Documents</th>
                      <th>Action</th>
                    </tr>
                    {rows}
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Admincustomers