import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Admindashboard = () => {
  const { apipath, adminLogout } = useAuth();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const admintoken = localStorage.getItem('admintoken');
  const [loading, setLoading] = useState(true);
  const [dashData, setdashData] = useState();
  const [customerData, setcustomerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState('');

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilters(value);
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...customerData];
    const filterValue = filters.toLowerCase(); if (filterValue) {
      filteredItems = filteredItems.filter(item => {
        return Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(filterValue)
        );
      });
    }
    setFilteredData(filteredItems);
  };
  useEffect(() => {
    applyFilters();
  }, [filters]);

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(
        apipath + 'admindashboard',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        }
      );
      setdashData(response.data);
      setcustomerData(response.data.customers);
      setFilteredData(response.data.customers);
      if (response.data.status === "false") {
        localStorage.removeItem('admintoken');
        navigate('/adminlogin');
      }
    }
    catch (error) {
      console.error('Error fetching dashboard data:', error);
      if (localStorage.getItem('admintoken')) {
        localStorage.removeItem('admintoken');
      }
      navigate('/adminlogin');

    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    checkAuthentication();
  }, []);


  const deletecustomer = (sid) => {
    const sdata = {
      id: sid,
    }
    try {
      axios.post(apipath + 'deletestaff', sdata,
        {
          headers: {
            Authorization: 'Bearer ' + admintoken
          }
        }
      )
        .then((response) => {
          var data = response.data;
          console.log(data);
          if (data.status === "false") {
            iziToast.error({
              message: data.message,
              position: "topCenter"
            });
          }
          else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
            checkAuthentication();
          }
        });
    }
    catch (error) {
      iziToast.error({
        message: "Something wrong please try again.",
        position: "topCenter"
      });
      console.error('Error fetching dashboard data:', error);

    }
  }
  console.log(dashData);


  if (loading) {
    return <div>Loading...</div>;
  }
  localStorage.setItem('customerData', JSON.stringify(dashData.customers));

  // const scolumns = [
  //   { name: 'Customer ID', selector: row => row.id, sortable: true, width: '150px' },
  //   { name: 'Name', selector: row => row.name, sortable: true },
  //   { name: 'Email', selector: row => row.email, sortable: true },
  //   { name: 'Phone Number', selector: row => row.phone, sortable: true },
  //   { name: 'Service', selector: row => row.service, sortable: true },
  //   {
  //     name: 'Action', cell: row => (
  //       <>
  //         <Link to={`/admineditcustomer/${row.id}`}><i class="bi bi-pencil viewbtn"></i> &nbsp;{" "}</Link>
  //         <i class="bi bi-trash3 viewbtn" onClick={() => deletecustomer(row.id)}></i>{" "}
  //       </>
  //     )
  //   },
  //   // Add more columns as needed
  // ];
  if (dashData.recentorders.length > 0) {
    var rowdata = dashData.recentorders;
    var orders = rowdata.map((ordr) => {
      return (
        <>

          <div className="row m-0 my-1 p-2 border rounded bg-light" style={{'cursor':'pointer'}} onClick={()=>{navigate(`/adminorderdetail/${ordr.id}`)}}>
            <div className="col-lg-6 col-md-12 col-12">
              <h5>#{ordr.order_id}</h5>
              <p className="m-0" style={{ 'font-size': '14px' }}><b>Name : </b>{ordr.user_name}</p>
            </div>
            <div className="col-lg-6 col-md-12 col-12 text-end">
              <p className="m-0" style={{ 'font-size': '14px' }}><b>{ordr.price}</b></p>
              <p className="m-0" style={{ 'font-size': '14px' }}><b>{ordr.payment_status}</b></p>
            </div>
            <div className="col-12">
              <p className="m-0" style={{ 'font-size': '14px' }}><b>Service : </b>{ordr.order_name}</p>
              <p className="m-0" style={{ 'font-size': '14px' }}><b>Status : </b>{ordr.status}</p>
              <span style={{ 'font-size': '12px' }}>{ordr.fulldate}</span>
            </div>
          </div>
        </>
      );
    });
  }
  else {
    orders = "No Orders.";
  }
  
  if (dashData.recenttickets.length > 0) {
    var rowdata = dashData.recenttickets;
    var tickets = rowdata.map((tiket) => {
      return (
        <>

          <div className="row m-0 my-1 p-2 border rounded bg-light" style={{'cursor':'pointer'}} onClick={()=>{navigate(`/adminticketview/${tiket.id}`)}}>
            <div className="col-lg-6 col-md-12 col-12">
              <h5>#{tiket.ticket_id}</h5>
              <p className="m-0" style={{ 'font-size': '14px' }}><b>Name : </b>{tiket.user_name}</p>
            </div>
            <div className="col-lg-6 col-md-12 col-12 text-end">
              <p className="m-0" style={{ 'font-size': '14px' }}><b>{tiket.priority}</b></p>
              <p className="m-0" style={{ 'font-size': '14px' }}><b>{tiket.status}</b></p>
            </div>
            <div className="col-12">
              <p className="m-0" style={{ 'font-size': '14px' }}><b>Service : </b>{tiket.service_name}</p>
              <p className="m-0" style={{ 'font-size': '14px' }}><b>Subject : </b>{tiket.subject}</p>
              <span style={{ 'font-size': '12px' }}>{tiket.fulldate}</span>
            </div>
          </div>
        </>
      );
    });
  }
  else {
    tickets = "No Tickets.";
  }
  return (
    <>
      <Admindashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/admindashboard`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-grid-1x2"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link
                  to={`/admincustomers`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/adminstaff`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Staff</span>
                </Link>
                <Link
                  to={`/adminorders`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Orders</span>
                </Link>
                <Link
                  to={`/adminchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/adminticket`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/adminservice`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Services</span>
                </Link>
                {/* <Link
                  to={`/admintask`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link> */}
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={adminLogout}
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="admin-dash" onClick={() => { navigate('/admincustomers') }} style={{ 'cursor': 'pointer' }}>
                  <div>
                    <img src="images/admin.png" width={49} height={49} alt="" />
                  </div>
                  <div>
                    <h6>Total Customers</h6>
                    <h5>{dashData.total_customers}</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="admin-dash" onClick={() => { navigate('/adminstaff') }} style={{ 'cursor': 'pointer' }}>
                  <div>
                    <img src="images/admin.png" width={49} height={49} alt="" />
                  </div>
                  <div>
                    <h6>Total Staff</h6>
                    <h5>{dashData.total_staff}</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3" onClick={() => { navigate('/adminorders') }} style={{ 'cursor': 'pointer' }}>
                <div className="admin-dash">
                  <div>
                    <img src="images/admin.png" width={49} height={49} alt="" />
                  </div>
                  <div>
                    <h6>Total Orders</h6>
                    <h5>{dashData.total_orders}</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="admin-dash" onClick={() => { navigate('/adminticket') }} style={{ 'cursor': 'pointer' }}>
                  <div>
                    <img src="images/admin.png" width={49} height={49} alt="" />
                  </div>
                  <div>
                    <h6>Tickets</h6>
                    <h5>{dashData.tickets}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <div className="row m-0">
                  <div className="col-lg-6 col-md-6 col-12 border-end">
                    <h4>Recent Orders</h4>
                    {orders}
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 border-start">
                    <h4>Recent Tickets</h4>
                    {tickets}
                    {/* <div className="row m-0 my-1 p-2 border rounded bg-light">
                      <div className="col-lg-6 col-md-12 col-12">
                        <h5>#LEID004</h5>
                        <p className="m-0" style={{ 'font-size': '14px' }}><b>Name : </b>Sandeep Kumar</p>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 text-end">
                        <p className="m-0" style={{ 'font-size': '14px' }}><b>High</b></p>
                        <p className="m-0" style={{ 'font-size': '14px' }}><b>Open</b></p>
                      </div>
                      <div className="col-12">
                        <p className="m-0" style={{ 'font-size': '14px' }}><b>Service : </b>Private Limited Company ( PLC ) Registration</p>
                        <p className="m-0" style={{ 'font-size': '14px' }}><b>Subject : </b>Private Limited Company ( PLC ) Registration</p>
                        <span style={{ 'font-size': '12px' }}>12-03-2024</span>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <h5>Customer</h5>
                <div>
                  <div className="row m-0" id="table_detail">
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Search"
                        value={filters.order_name}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-2 p-1">
                      <button onClick={applyFilters}>Search</button>
                    </div>
                  </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Admindashboard