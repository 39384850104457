import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import eko from '../eko';
import { useAuth } from '../AuthContext';
const Dashnavbar = () => {
    const { apipath } = useAuth();
    const navigate = useNavigate();
    const userid = localStorage.getItem('userid');
    const token = localStorage.getItem('token');
    const [unread, setunread] = useState(0);
    const [myNotifications, setNotifications] = useState({
        notifications: []
    });

    const readNotification = async () => {
        try {
            axios.post(apipath + 'readnotification',
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(response => {
                    setunread(response.data.readcount);
                    // console.log(response.data);
                    if (response.data.status === "false") {
                        localStorage.removeItem('token');
                        navigate('/login');
                    }
                })
                .catch(error => {
                    console.error('Error fetching messages:', error);
                });
        }
        catch (error) {
            console.error('Error fetching dashboard data:', error);
            if (localStorage.getItem('token')) {
                localStorage.removeItem('token');
            }
            navigate('/login');

        }
    }
    const checkAuthentication = async () => {
        try {
            axios.post(apipath + 'getnotification',
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(response => {
                    setNotifications(response.data);
                    setunread(response.data.readcount);
                    // console.log(response.data);
                    if (response.data.status === "false") {
                        localStorage.removeItem('token');
                        navigate('/login');
                    }
                })
                .catch(error => {
                    console.error('Error fetching messages:', error);
                });
        }
        catch (error) {
            console.error('Error fetching dashboard data:', error);
            if (localStorage.getItem('token')) {
                localStorage.removeItem('token');
            }
            navigate('/login');

        }
    }
    useEffect(() => {
        checkAuthentication();
    }, []);

    useEffect(() => {
        const handleNewMessage = (data) => {
            // console.log(data);
            setNotifications(prevState => {
                return {
                    ...prevState,
                    notifications: [...prevState.notifications, data.message]
                };
            });
        };

        const channel = eko.channel('notification.' + userid);
        channel.listen('.notification.sent', handleNewMessage);

        // Cleanup function to unsubscribe from the channel
        return () => {
            channel.stopListening('.notification.sent', handleNewMessage);
        };
    }, [unread]);


    if (myNotifications.notifications.length > 0) {
        // console.log("find some messages");
        var mymsgs = myNotifications.notifications;
        // console.log(mymsgs);
        var showmsg = mymsgs.map((msg) => {
            var linkmsg = '';
            if (msg.title === 'New Message') {
                if (msg.order_id) {
                    linkmsg = '/chat/' + msg.order_id;
                }
                else {
                    linkmsg = '/chat';
                }

            }
            if (msg.title === 'Order Status Update') {
                if (msg.order_id) {
                    linkmsg = '/orderprogress/' + msg.order_id;
                }
            }
            if (msg.title === 'Ticket Reply' || msg.title === 'Ticket Status Updated') {
                if (msg.ticket_id) {
                    linkmsg = '/ticketdetail/' + msg.ticket_id;
                }
            }
            return (
                <>
                    <Link to={linkmsg}>
                        <li className="my-1 bg-light">
                            <div class="dropdown-item notification" style={{ 'width': '100%', 'wordWrap': 'break-word', 'whiteSpace': 'normal' }}>
                                <h6 className="m-0">{msg.title}</h6>
                                <span className="m-0" style={{ 'wordWrap': 'break-word', 'width': '100%' }}>{msg.detail}</span>
                            </div>
                        </li>
                    </Link>
                </>
            );
        });
    }
    else {
        showmsg = "";
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="inner-nav">
                        <div className='nav-imgbox'>
                            <Link to={'/dashboard'}>
                                <img src="/logo.png" alt="" />
                            </Link>
                        </div>
                        <div className="notify-navbox">
                            <div className="dropdown">
                                <button className="border-0 bg-white" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { readNotification() }}>
                                    <i className="fa-regular fa-bell">
                                        <sup className="bg-danger p-1 text-white rounded"><small>{unread}</small></sup>
                                    </i>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ 'max-height': '300px', 'overflow': 'auto', 'width': '200px' }}>
                                    {showmsg}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashnavbar