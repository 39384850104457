import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
const Admintask = () => {
  const { apipath, adminLogout } = useAuth();
  const [show, setShow] = useState(true);
  const { customerId } = useParams();
  return (
    <>
      <Admindashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/admindashboard`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-grid-1x2"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link
                  to={`/admincustomers`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/adminstaff`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Staff</span>
                </Link>
                <Link
                  to={`/adminorders`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-box-seam"></i>
                  <span className="nav-link-name">Orders</span>
                </Link>
                <Link
                  to={`/adminchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/adminticket`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/adminservice`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Services</span>
                </Link>
                {/* <Link
                  to={`/admintask`}
                  className="nav-linksidebar active-bar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link> */}
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={adminLogout}
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">

          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>Tasks</h5>
                <div class="new-filter">
                  <div class="input-filterbox">
                    <input
                      class="search me-2"
                      type="search"
                      placeholder="Search..."
                    />
                    <input type="date" className="form-control" />
                  </div>
                </div>
                <div>
                  <table id="table_detail">
                    <tr>
                      <th>Task ID</th>
                      <th>Task Name</th>
                      <th>Price</th>
                      <th>Timeline</th>
                      <th>Category</th>
                      <th>Task Status</th>
                      <th>Documents Upload</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <td>#5688</td>
                      <td>Private Limited Company</td>
                      <td>10,000</td>
                      <td>10 days</td>
                      <td>Legal Services</td>
                      <td>Pending</td>
                      <td>Completed</td>
                      <td>
                        <i class="bi bi-pencil"></i> &nbsp;
                        <i class="bi bi-eye"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>#5688</td>
                      <td>Private Limited Company</td>
                      <td>10,000</td>
                      <td>10 days</td>
                      <td>Legal Services</td>
                      <td>Pending</td>
                      <td>Completed</td>
                      <td>
                        <i class="bi bi-pencil"></i> &nbsp;
                        <i class="bi bi-eye"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>#5688</td>
                      <td>Private Limited Company</td>
                      <td>10,000</td>
                      <td>10 days</td>
                      <td>Legal Services</td>
                      <td>Pending</td>
                      <td>Completed</td>
                      <td>
                        <i class="bi bi-pencil"></i> &nbsp;
                        <i class="bi bi-eye"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>#5688</td>
                      <td>Private Limited Company</td>
                      <td>10,000</td>
                      <td>10 days</td>
                      <td>Legal Services</td>
                      <td>Pending</td>
                      <td>Completed</td>
                      <td>
                        <i class="bi bi-pencil"></i> &nbsp;
                        <i class="bi bi-eye"></i>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </main>
    </>
  )
}

export default Admintask