import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Home } from './components/home';
import Servicedetail from './components/Servicedetail';
import Register from './components/Register';
import Login from './components/Login';
import Forgetpassword from './components/Forgetpassword';
import Otp from './components/Otp';
import Payment from './components/Payment';
import Dashboard from './components/Dashboard';
import Services from './components/Services';
import Buyservices from './components/Buyservices';
import Details from './components/Details';
import Orderdetail from './components/Orderdetail';
import Mydocuments from './components/Mydocuments';
import Expireddocuments from './components/Expireddocuments';
import Myorders from './components/Myorders';
import Chat from './components/Chat';
import Orderprogress from './components/Orderprogress';
import Raiseticket from './components/Raiseticket';
import Ticketlisting from './components/ticketlisting';
import Ticketdetail from './components/ticketdetail';
import Adminlogin from './Admin/Adminlogin';
import Admindashboard from './Admin/Admindashboard';
import Admincustomers from './Admin/Admincustomers';
import Adminstaff from './Admin/Adminstaff';
import Adminchat from './Admin/Adminchat';
import Adminaddstaff from './Admin/Adminaddstaff';
import Admineditstaff from './Admin/Admineditstaff';
import Admineditcustomer from './Admin/Admineditcustomer';
import Adminticket from './Admin/Adminticket';
import Adminticketview from './Admin/Adminticketview';
import Adminservice from './Admin/Adminservice';
import Adminservicedetails from './Admin/Adminservicedetails';
import Adminupdateservice from './Admin/Adminupdateservice';
import Adminorderdetail from './Admin/Adminorderdetail';
import Admintask from './Admin/Admintask';
import Adminorders from './Admin/Adminorders';
import Adminaddorder from './Admin/Adminaddorder';
import Staffaddorder from './Staff/Staffaddorder';
import Adminorderprogress from './Admin/Adminorderprogress';
import Stafflogin from './Staff/Stafflogin';
import Staffregister from './Staff/Staffregister';
import Staffticket from './Staff/Staffticket';
import Staffchat from './Staff/Staffchat';
import Staffcustomer from './Staff/Staffcustomer';
import Staffticketview from './Staff/Staffticketview';
import Stafftask from './Staff/Stafftask';
import Stafftaskdetail from './Staff/Stafftaskdetail';
function App() {
  return (
    <>
    <Router>
    <Routes>
    <Route exact path="/" element={ <Home /> } />
    <Route exact path="/servicedetail" element={ <Servicedetail /> } />
    <Route exact path="/details/:id" element={ <Details /> } />
    <Route exact path="/orderdetail/:id" element={ <Orderdetail /> } />
    <Route exact path="/register" element={ <Register /> } />
    <Route exact path="/login" element={ <Login /> } />
    <Route exact path="/forgetpassword" element={ <Forgetpassword /> } />
    <Route exact path="/otp" element={ <Otp /> } />
    <Route exact path="/payment/:id" element={ <Payment /> } />
    <Route exact path="/dashboard" element={ <Dashboard /> } />
    <Route exact path="/services" element={ <Services /> } />
    <Route exact path="/buyservices" element={ <Buyservices /> } />
    <Route exact path="/mydocuments" element={ <Mydocuments /> } />
    <Route exact path="/expireddocuments" element={ <Expireddocuments /> } />
    <Route exact path="/myorders" element={ <Myorders /> } />
    <Route exact path="/chat" element={ <Chat /> } />
    <Route exact path="/chat/:id" element={ <Chat /> } />
    <Route exact path="/orderprogress/:id" element={ <Orderprogress /> } />
    <Route exact path="/raiseticket" element={ <Raiseticket /> } />
    <Route exact path="/ticketlisting" element={ <Ticketlisting /> } />
    <Route exact path="/ticketdetail/:id" element={ <Ticketdetail /> } />
    <Route exact path="/adminlogin" element={ <Adminlogin /> } />
    <Route exact path="/admindashboard" element={ <Admindashboard /> } />
    <Route exact path="/admincustomers" element={ <Admincustomers /> } />
    <Route exact path="/adminstaff" element={ <Adminstaff /> } />
    <Route exact path="/adminchat" element={ <Adminchat /> } />
    <Route exact path="/adminchat/:id" element={ <Adminchat /> } />
    <Route exact path="/staffchat" element={ <Staffchat /> } />
    <Route exact path="/staffchat/:id" element={ <Staffchat /> } />
    <Route exact path="/adminaddstaff" element={ <Adminaddstaff /> } />
    <Route exact path="/admineditstaff/:id" element={ <Admineditstaff /> } />
    <Route exact path="/admineditcustomer/:id" element={ <Admineditcustomer /> } />
    <Route exact path="/adminticket" element={ <Adminticket /> } />
    <Route exact path="/adminticketview/:id" element={ <Adminticketview /> } />
    <Route exact path="/adminservice" element={ <Adminservice /> } />
    <Route exact path="/adminservicedetails/:id" element={ <Adminservicedetails /> } />
    <Route exact path="/adminupdateservice/:id" element={ <Adminupdateservice /> } />
    <Route exact path="/adminorderdetail/:id" element={ <Adminorderdetail /> } />
    <Route exact path="/adminorders" element={ <Adminorders /> } />
    <Route exact path="/adminaddorder" element={ <Adminaddorder /> } />
    <Route exact path="/staffaddorder" element={ <Staffaddorder /> } />
    <Route exact path="/admintask" element={ <Admintask /> } />
    <Route exact path="/adminorderprogress/:id" element={ <Adminorderprogress /> } />
    <Route exact path="/stafflogin" element={ <Stafflogin /> } />
    <Route exact path="/staffregister" element={ <Staffregister /> } />
    <Route exact path="/staffcustomer" element={ <Staffcustomer />} />
    <Route exact path="/staffticket" element={ <Staffticket />} />
    <Route exact path="/staffticketview/:id" element={ <Staffticketview />} />
    <Route exact path="/stafftask" element={ <Stafftask />} />
    <Route exact path="/stafftaskdetail/:id" element={ <Stafftaskdetail />} />
        </Routes>
        </Router>
        
    </>
  );
}

export default App;
