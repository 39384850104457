import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Ticketlisting = () => {
  const [show, setShow] = useState(true);
  const { logout, apipath } = useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [ticketData, setticketData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState('');
  const today = new Date().toISOString().split('T')[0];


  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilters(value);
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...ticketData];
    const filterValue = filters.toLowerCase(); if (filterValue) {
      filteredItems = filteredItems.filter(item => {
        return Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(filterValue)
        );
      });
    }
    setFilteredData(filteredItems);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'mytickets',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        );
        setticketData(response.data.tickets);
        setFilteredData(response.data.tickets);
        if (response.data.status === "false") {
          localStorage.removeItem('token');
          navigate('/login');
        }
      }
      catch (error) {
        console.error('Error fetching orders data:', error);
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
        navigate('/login');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }

  localStorage.setItem('ticketData', JSON.stringify(ticketData));

  const scolumns = [
    { name: 'Ticket ID', selector: row => row.ticket_id, sortable: true, width: '130px' },
    { name: 'Department', selector: row => row.department, sortable: true },
    { name: 'Subject', selector: row => row.subject, sortable: true, width: '250px' },
    { name: 'Status', selector: row => row.status, sortable: true },
    { name: 'Priority', selector: row => row.priority, sortable: true },
    { name: 'Date (yyyy-mm-dd)', selector: row => row.date, sortable: true, width: '200px' },
    // { name: 'Documents Upload', selector: row => "Pending", sortable: true },
    {
      name: 'Action', cell: row => (
        <Link to={`/ticketdetail/${row.id}`}><i class="bi bi-eye viewbtn"></i></Link>
      )
    },
    // Add more columns as needed
  ];

  // if (ticketData.tickets.length > 0) {
  //   var rowdata = ticketData.tickets;
  //   var rows = rowdata.map((ticket) => {
  //     return (
  //       <>
  //         <tr>
  //           <td>{ticket.ticket_id}</td>
  //           <td>{ticket.department}</td>
  //           <td>{(ticket.subject.length > 50) ? ticket.subject.substr(0, 50) + '...' : ticket.subject.substr(0, 50)}</td>
  //           <td>{ticket.status}</td>
  //           <td>{ticket.priority}</td>
  //           <td>{ticket.date}</td>
  //           <td>
  //             <Link to={`/ticketdetail/${ticket.id}`}><i class="bi bi-eye viewbtn"></i></Link> &nbsp;
  //             {/* <Link to={`/orderprogress/${ticket.id}`}><button >Progress</button></Link> */}
  //             {/* <i class="bi bi-trash3"></i> &nbsp; */}
  //             {/* <i class="bi bi-download"></i> &nbsp; */}
  //           </td>
  //         </tr>
  //       </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No Tickets.";
  // }
  return (
    <>
      <Dashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <Sidebar activePage={'raiseticket'} />

        <div className="home-container">
          <div className="container pt-2 pb-3">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>Ticket List</h5>
                <div>
                  <div className="row m-0" id="table_detail">
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="order_name"
                        placeholder="Search by name"
                        value={filters.order_name}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="date"
                        name="date"
                        max={today}
                        placeholder="Search by Date"
                        value={filters.date}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-2 p-1">
                      <button onClick={applyFilters}>Search</button>
                    </div>
                  </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>Ticket ID</th>
                      <th>Department</th>
                      <th>Subject</th>
                      <th>Status</th>
                      <th>Priority</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                    {rows}

                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Ticketlisting