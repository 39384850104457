import React from 'react'

const footer = () => {
  return (
    <>
      <div className="container-fluid footer-container">
        <div className="row footer-row">
          <div className="col-md-6 col-lg-3">
            <div className="footer-box">
              <h4 className="mb-2">TaxAj</h4>
              {/* <h5>Subscribe to our newsletter</h5>
              <h6 className="pb-2">Get product updates, company news, and more.</h6>
              <button className="foot-button mt-4">
                <p>Subscribe</p>
              </button> */}
            </div>
          </div>
          {/* <div className="col-6 col-md-6 col-lg-2">
            <div className="footer-box1">
              <h5>Product</h5>
              <ul>
                <li>Features</li>
                <li>Security</li>
                <li>Team</li>
                <li>Enterprise</li>
                <li>Customer Stories</li>
                <li>Pricing</li>
                <li>Resources</li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-2">
            <div className="footer-box1">
              <h5>Platform</h5>
              <ul>
                <li>Features</li>
                <li>Security</li>
                <li>Team</li>
                <li>Enterprise</li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-2">
            <div className="footer-box1">
              <h5>Support</h5>
              <ul>
                <li>Features</li>
                <li>Security</li>
                <li>Team</li>
                <li>Enterprise</li>
                <li>Customer Stories</li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-2">
            <div className="footer-box1">
              <h5>Company</h5>
              <ul>
                <li>Features</li>
                <li>Security</li>
                <li>Team</li>
                <li>Enterprise</li>
                <li>Customer Stories</li>
                <li>Pricing</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default footer