import React from "react";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <>
      <div className="header-contact">
        <div className="d-flex contact-info">
          <p className="cont-number-head me-3">
            {" "} <i className='bx bx-phone-call me-2'></i>
            <Link to="tel:8802812345" className="cont-number">+918802812345</Link>{" "}
          </p>
          <p>
            {" "} <i className="bi bi-envelope me-2"></i>
            <Link to="" className="cont-mail">connect@taxaj.com</Link>{" "}
          </p>
        </div>
        <form className="landing-buttons">
          <Link to="/login" className="login-button me-3">Login</Link>
          <Link to="/register" className="register-button">Register <i className="bi bi-arrow-right"></i></Link>
        </form>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="/logo.png" width="135" height="61" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"> <img src="menu.png" alt="" /> </span>
          </button>
          {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="me-auto mb-2 mb-lg-0"></ul>
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" to="/about">
                  Launch
                </Link>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><Link className="dropdown-item" to="#">Popular Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Special Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Global Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Brand Register</Link></li>
                  <li><Link className="dropdown-item" href="#">Digital Presence</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" to="/about">
                  Manage
                </Link>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><Link className="dropdown-item" href="#">Popular Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Special Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Global Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Brand Register</Link></li>
                  <li><Link className="dropdown-item" href="#">Digital Presence</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" to="/about">
                  License
                </Link>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><Link className="dropdown-item" href="#">Popular Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Special Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Global Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Brand Register</Link></li>
                  <li><Link className="dropdown-item" href="#">Digital Presence</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" to="/about">
                  Filings
                </Link>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><Link className="dropdown-item" href="#">Popular Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Special Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Global Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Brand Register</Link></li>
                  <li><Link className="dropdown-item" href="#">Digital Presence</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" to="/about">
                  Legal
                </Link>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><Link className="dropdown-item" href="#">Popular Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Special Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Global Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Brand Register</Link></li>
                  <li><Link className="dropdown-item" href="#">Digital Presence</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" to="/about">
                  Individual
                </Link>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><Link className="dropdown-item" href="#">Popular Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Special Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Global Entities</Link></li>
                  <li><Link className="dropdown-item" href="#">Brand Register</Link></li>
                  <li><Link className="dropdown-item" href="#">Digital Presence</Link></li>
                </ul>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
