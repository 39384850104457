import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Services = () => {
  const [show, setShow] = useState(true);
  // const { customerId } = useParams();
  const { logout, apipath } = useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [serviceData, setserviceData] = useState();
  const [sdata, setsdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ name: '', category: '' });

  const [serviceInfo, setserviceInfo] = useState({
    myservices: [],
    servPrice: 0,
  });
  const [selamount, setselamount] = useState(0);

  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const price = parseInt(e.target.getAttribute('data-price'));
    // console.log(price);
    const { myservices, servPrice } = serviceInfo;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setserviceInfo({
        myservices: [...myservices, value],
        servPrice: selamount + price,
      });
      setselamount(
        selamount + price,
      )
      console.log(selamount);
    }

    // Case 2  : The user unchecks the box
    else {
      setserviceInfo({
        myservices: myservices.filter(
          (e) => e !== value
        ),
        servPrice: selamount - price,
      });
      setselamount(
        selamount - price,
      )
      console.log(selamount);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const filteredItems = sdata.filter(item =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredItems);
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };
  const applyFilters = () => {
    let filteredItems = [...sdata];
    Object.keys(filters).forEach(key => {
      const filterValue = filters[key].toLowerCase();
      if (filterValue) {
        filteredItems = filteredItems.filter(item =>
          item[key].toLowerCase().includes(filterValue)
        );
      }
    });
    setFilteredData(filteredItems);
  };
  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    console.log(serviceInfo);
    var multipaybtn = document.getElementById('multipaybtn');
    if (serviceInfo.myservices.length > 0) {
      document.getElementById('multipaybutton').style.display = 'block';
      multipaybtn.innerHTML = "Buy Selected(" + serviceInfo.myservices.length + " - Rs." + selamount + ")";
    }
    else {
      if (multipaybtn) {
        document.getElementById('multipaybutton').style.display = 'none';
      }
    }
  }, [serviceInfo]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'services',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        );
        setserviceData(response.data);
        setsdata(response.data.services);
        setFilteredData(response.data.services);
        if (response.data.status === "false") {
          localStorage.removeItem('token');
          navigate('/login');
        }
      }
      catch (error) {
        console.error('Error fetching orders data:', error);
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
        navigate('/login');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);
  // console.log(serviceData);
  const handleBuy = () => {
    if (serviceInfo.myservices.length > 0) {
      localStorage.setItem('buyservices', JSON.stringify(serviceInfo))
      const bservices = serviceInfo.myservices;
      navigate(`/buyservices`);
    }
  }


  if (loading) {
    return <div>Loading...</div>;
  }


  const scolumns = [
    { name: 'ID', selector: row => row.id, sortable: true, width: '80px' },
    { name: 'Service Name', selector: row => row.name, sortable: true, width: '250px' },
    { name: 'Category', selector: row => row.category, sortable: true, width: '200px' },
    { name: 'Price', selector: row => row.price, sortable: true, width: '120px' },
    { name: 'Timeline', selector: row => row.sla, sortable: true, width: '120px' },
    {
      name: 'Purchase', cell: row => (
        <>
          <div id="table_detail" class="d-flex align-items-center">
            <Link to={`/payment/${row.id}`}><button>Buy</button></Link>
            <div class="switch-box">
              <input type="checkbox" id={`tooglebtn${row.id}`} name="services[]" data-price={row.price} value={row.id} onChange={handleChange} />
              <button className="mx-1 addbtn">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add+</button>
            </div>
          </div>
        </>
      ), width: '200px'
    },
    {
      name: 'Action', cell: row => (
        <Link to={`/details/${row.id}`}><i class="bi bi-eye viewbtn"></i></Link>
      ), width: '120px'
    },
    // Add more columns as needed
  ];

  localStorage.setItem('serviceData', JSON.stringify(serviceData.services));
  // if (serviceData.services.length > 0) {
  //   var rowdata = serviceData.services;
  //   var rows = rowdata.map((serv) => {
  //     return (
  //       <>
  //         <tr>
  //           <td>{serv.id}</td>
  //           <td>{(serv.name.length > 50) ? serv.name.substr(0, 50) + '...' : serv.name.substr(0, 50)}</td>
  //           <td>{serv.category}</td>
  //           <td>{serv.price}</td>
  //           <td>{serv.sla}</td>
  //           <td className="d-flex align-items-center">
  //             <Link to={`/payment/${serv.id}`}><button>Buy</button></Link>
  //             <div class="switch-box">
  //               <input type="checkbox" id={`tooglebtn${serv.id}`} name="services[]" data-price={serv.price} value={serv.id} onChange={handleChange} />
  //               <button className="mx-1 addbtn">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add+</button>
  //             </div>
  //           </td>
  //           <td><Link to={`/details/${serv.id}`}><i class="bi bi-eye viewbtn"></i></Link></td>
  //         </tr>
  //       </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No services.";
  // }
  return (
    <>
      <Dashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <Sidebar activePage={window.location.pathname.split('/')[1]} />

        <div className="fixed-button-container" id="multipaybutton">
          <button className="fixed-button" id="multipaybtn" onClick={handleBuy}>Buy Selected</button>
        </div>

        <div className="home-container">
          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>Our Services</h5>
                {/* <div class="new-filter">
                  <div class="input-filterbox">
                    <select class="form-select me-2" aria-label="Default select example">
                      <option selected>Launch Your Start Up</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <input type="date" className="form-control" />
                  </div>
                </div> */}
                <div>
                  <div className="row m-0" id="table_detail">
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Search by name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="category"
                        placeholder="Search by Category"
                        value={filters.category}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-2 p-1">
                      <button onClick={applyFilters}>Search</button>
                    </div>
                  </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={sdata.length}
                    paginationRowsPerPageOptions={[sdata.length]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>ID</th>
                      <th>Service Name</th>
                      <th>Category</th>
                      <th>Price</th>
                      <th>Timeline</th>
                      <th>Purchase</th>
                      <th>Action</th>
                    </tr>
                    {rows}
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Services