import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import {} from "react-router-dom";
const Servicedetail = () => {
  return (
    <>
      <Navbar />

      <div className="container pt-5 mt-5">
        <div className="row service-detail-row pt-5">
          <h6 className="pb-4">Services</h6>
          <div className="col-lg-3">
            <div
              className="nav flex-column nav-pills me-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className="nav-link active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                Private Limited Company
              </button>
              <hr className="detail-line" />
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                Public Limited Company
              </button>
              <hr className="detail-line" />
              <button
                className="nav-link"
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                Limited Liability Partnership
              </button>
              <hr className="detail-line" />
              <button
                className="nav-link"
                id="v-pills-settings-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                Partnership Firm
              </button>
              <hr className="detail-line" />
              <button
                className="nav-link"
                id="v-pills-settings1-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings1"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                Proprietorship Firm
              </button>
              <hr className="detail-line" />
              <button
                className="nav-link"
                id="v-pills-settings2-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings2"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                OPC Pvt Ltd Company
              </button>
              <hr className="detail-line" />
            </div>
          </div>
          <div className="col-lg-9">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <div className="vertical-tabs">
                  <h4>
                    Private Limited Company <br />
                    Registration
                  </h4>
                  <p>
                    Are you thinking of starting a company? If so, you'll need
                    to register your business with the government. This process
                    can seem daunting, but we're here to help. In this blog
                    post, we'll walk you through the basics of company
                    registration in India. First, you'll need to choose a
                    business structure. This will determine the legal framework
                    for your company and how it will be taxed. Common business
                    structures include sole proprietorships, partnerships,
                    limited liability partnerships (LLPs), and Companies. Once
                    you've chosen a business structure, you'll need to obtain a
                    business license or permit from your local government. This
                    will allow you to operate your business legally.
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <div className="vertical-tabs">
                  <h4>
                    Public Limited Company <br />
                    Registration
                  </h4>
                  <p>
                    Are you thinking of starting a company? If so, you'll need
                    to register your business with the government. This process
                    can seem daunting, but we're here to help. In this blog
                    post, we'll walk you through the basics of company
                    registration in India. First, you'll need to choose a
                    business structure. This will determine the legal framework
                    for your company and how it will be taxed. Common business
                    structures include sole proprietorships, partnerships,
                    limited liability partnerships (LLPs), and Companies. Once
                    you've chosen a business structure, you'll need to obtain a
                    business license or permit from your local government. This
                    will allow you to operate your business legally.
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                <div className="vertical-tabs">
                  <h4>Limited Liability Partnership</h4>
                  <p>
                    Are you thinking of starting a company? If so, you'll need
                    to register your business with the government. This process
                    can seem daunting, but we're here to help. In this blog
                    post, we'll walk you through the basics of company
                    registration in India. First, you'll need to choose a
                    business structure. This will determine the legal framework
                    for your company and how it will be taxed. Common business
                    structures include sole proprietorships, partnerships,
                    limited liability partnerships (LLPs), and Companies. Once
                    you've chosen a business structure, you'll need to obtain a
                    business license or permit from your local government. This
                    will allow you to operate your business legally.
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <div className="vertical-tabs">
                  <h4>Partnership Firm</h4>
                  <p>
                    Are you thinking of starting a company? If so, you'll need
                    to register your business with the government. This process
                    can seem daunting, but we're here to help. In this blog
                    post, we'll walk you through the basics of company
                    registration in India. First, you'll need to choose a
                    business structure. This will determine the legal framework
                    for your company and how it will be taxed. Common business
                    structures include sole proprietorships, partnerships,
                    limited liability partnerships (LLPs), and Companies. Once
                    you've chosen a business structure, you'll need to obtain a
                    business license or permit from your local government. This
                    will allow you to operate your business legally.
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings1"
                role="tabpanel"
                aria-labelledby="v-pills-settings1-tab"
              >
                <div className="vertical-tabs">
                  <h4>Proprietorship Firm</h4>
                  <p>
                    Are you thinking of starting a company? If so, you'll need
                    to register your business with the government. This process
                    can seem daunting, but we're here to help. In this blog
                    post, we'll walk you through the basics of company
                    registration in India. First, you'll need to choose a
                    business structure. This will determine the legal framework
                    for your company and how it will be taxed. Common business
                    structures include sole proprietorships, partnerships,
                    limited liability partnerships (LLPs), and Companies. Once
                    you've chosen a business structure, you'll need to obtain a
                    business license or permit from your local government. This
                    will allow you to operate your business legally.
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings2"
                role="tabpanel"
                aria-labelledby="v-pills-settings2-tab"
              >
                <div className="vertical-tabs">
                  <h4>OPC Pvt Ltd Company</h4>
                  <p>
                    Are you thinking of starting a company? If so, you'll need
                    to register your business with the government. This process
                    can seem daunting, but we're here to help. In this blog
                    post, we'll walk you through the basics of company
                    registration in India. First, you'll need to choose a
                    business structure. This will determine the legal framework
                    for your company and how it will be taxed. Common business
                    structures include sole proprietorships, partnerships,
                    limited liability partnerships (LLPs), and Companies. Once
                    you've chosen a business structure, you'll need to obtain a
                    business license or permit from your local government. This
                    will allow you to operate your business legally.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row bottom-service-row pt-4 pb-5 mt-3">
          <div className="col-lg-3">
            <div className="left-service">
              <div className="d-flex">
                <img
                  className="me-2"
                  src="/images/plan.png"
                  width={41}
                  height={41}
                  alt=""
                />
                <h5>About This Plan</h5>
              </div>
              <span>
                Get your private limited company registered in the fastest
                possible manner.
              </span>
              <hr />
              <div className="d-flex">
                <img
                  className="me-2"
                  src="/images/time.png"
                  width={41}
                  height={41}
                  alt=""
                />
                <h5>About This Plan</h5>
              </div>
              <span>
                Get your private limited company registered in the fastest
                possible manner.
              </span>{" "}
              <br />
              <button className="price-button mt-4">
                {" "}
                Pricing Starts INR 10,000{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="right-service">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Services Covered
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Who Should Buy
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    How Its Done
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="contact-tab1"
                    data-bs-toggle="tab"
                    data-bs-target="#contact1"
                    type="button"
                    role="tab"
                    aria-controls="contact1"
                    aria-selected="false"
                  >
                    Documents Required
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="tax-tab-content pt-3">
                    <ul>
                      <li>DSC (2 nos)</li>
                      <li>Filing of SPICe+ Form</li>
                      <li>
                        Issue of Incorporation Certificate along with PAN and
                        TAN
                      </li>
                      <li>
                        Includes Govt Fees & Stamp duty for Authorised Capital
                        upto Rs. 1 Lakh except for the states of Punjab, Madhya
                        Pradesh and Kerala
                      </li>
                      <li>
                        Excludes foreign national / Body Corporate as director
                        or business needing RBI/SEBI approval
                      </li>
                      <li>Assistance in Opening Bank Account</li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="tax-tab-content pt-3">
                    <ul>
                      <li>
                        Businesses looking to expand or scale operations on
                        higher level
                      </li>
                      <li>Startups looking to raise capital and issue ESOPs</li>
                      <li>
                        Businesses looking to convert their existing firm
                        structure into private limited company
                      </li>
                      <li>
                        Businesses aiming to work globally or with reputed
                        clients
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div className="tax-tab-content pt-3">
                    <ul>
                      <li>DSC Application</li>
                      <li>Name approval form filing</li>
                      <li>Preparation of Incorporation Documents</li>
                      <li>
                        Getting those docs signed by the respective stakeholders
                      </li>
                      <li>Filing of e-Forms with ROC</li>
                      <li>
                        Receipt of Incorporation Certificate with PAN, TAN, GST,
                        EPF, ESI & Bank Account.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="contact1"
                  role="tabpanel"
                  aria-labelledby="contact-tab1"
                >
                  <div className="tax-tab-content pt-3">
                    <ul>
                      <li>
                        Name, Contact Number and Email Id of all the
                        Stakeholders.
                      </li>
                      <li>Directors Identification Number, if already.</li>
                      <li>
                        Self Attested PAN, Aadhar & Passport size photo of all
                        the Stakeholders.
                      </li>
                      <li>
                        Apostilled Passport, Mobile Bill and other KYC docs in
                        case of NRI Stakeholder.
                      </li>
                      <li>
                        Latest Month Personal Bank statement of all the
                        Stakeholders.
                      </li>
                      <li>Specimen Signatures of all Stakeholders.</li>
                      <li>Few Proposed Business Names along with Objects.</li>
                      <li>
                        Latest Electricity Bill/Landline Bill of Registered
                        Office.
                      </li>
                      <li>
                        NOC from owner of registered office, If Owned. (Download
                        Template)
                      </li>
                      <li>
                        Rent Agreement from Landlord, If Rented/Leased.
                        (Download Template)
                      </li>
                      <li>
                        Brief description of main business activities of the
                        proposed Company.
                      </li>
                      <li>
                        Shareholding pattern (50:50 or 60:40) between the
                        Stakeholders.
                      </li>
                      <li>
                        Authorised & Paid Up Share Capital of the Company.
                      </li>
                      <li>Fill This Sheet For Remaining Details</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-5">
        <div className="video-heading">
          <h4>
            Explore OUR <br />
            <span>Video</span>{" "}
          </h4>
        </div>
        <div className="row video-box-row pt-4 mt-4">
          <div className="col-lg-6">
            <div className="video-viewbox">
              <iframe
                src="https://www.youtube.com/embed/zVzgBmgICaA?si=MGD14h30bEF3tZQc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="video-contentbox">
              <h5>Private Limited Company Registration</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="faq-heading">
          <h5>Frequently Asked Questions</h5>
        </div>
        <div className="row pt-5 pb-5">
          <div className="col-lg-4">
            <div className="faq-box">
              <img src="/images/faq.png" width={35} height={35} alt="" />
              <h4 className="pt-3">How do I change my account email?</h4>
              <p>
                You can log in to your account and change it from your Profile
                Edit Profile. Then go to the general tab to change your email.
                You can log in to your account and change it from your Profile
                Edit Profile.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="faq-box">
              <img src="/images/faq.png" width={35} height={35} alt="" />
              <h4 className="pt-3">How do I change my account email?</h4>
              <p>
                You can log in to your account and change it from your Profile
                Edit Profile. Then go to the general tab to change your email.
                You can log in to your account and change it from your Profile
                Edit Profile.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="faq-box">
              <img src="/images/faq.png" width={35} height={35} alt="" />
              <h4 className="pt-3">How do I change my account email?</h4>
              <p>
                You can log in to your account and change it from your Profile
                Edit Profile. Then go to the general tab to change your email.
                You can log in to your account and change it from your Profile
                Edit Profile.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="faq-box">
              <img src="/images/faq.png" width={35} height={35} alt="" />
              <h4 className="pt-3">How do I change my account email?</h4>
              <p>
                You can log in to your account and change it from your Profile
                Edit Profile. Then go to the general tab to change your email.
                You can log in to your account and change it from your Profile
                Edit Profile.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="faq-box">
              <img src="/images/faq.png" width={35} height={35} alt="" />
              <h4 className="pt-3">How do I change my account email?</h4>
              <p>
                You can log in to your account and change it from your Profile
                Edit Profile. Then go to the general tab to change your email.
                You can log in to your account and change it from your Profile
                Edit Profile.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="faq-box">
              <img src="/images/faq.png" width={35} height={35} alt="" />
              <h4 className="pt-3">How do I change my account email?</h4>
              <p>
                You can log in to your account and change it from your Profile
                Edit Profile. Then go to the general tab to change your email.
                You can log in to your account and change it from your Profile
                Edit Profile.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Servicedetail;
