import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
const OrderDetail = () => {
    const [show, setShow] = useState(true);
    // const { customerId } = useParams();
    const { logout, apipath } = useAuth();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState();

    const checkAuthentication = async () => {
        try {
            const response = await axios.post(
                apipath + 'order_detail/' + id,
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                }
            );
            setOrder(response.data.order);
            console.log(response.data);
            if (response.data.status === "false" && response.data.message === "Unauthentic access") {
                localStorage.removeItem('token');
                navigate('/login');
            }
            if (response.data.status === "false" && response.data.message !== "Unauthentic access") {
                iziToast.error({
                    message: response.data.message,
                    position: "topCenter"
                });
            }
        }
        catch (error) {
            console.error('Error fetching orders data:', error);
            if (localStorage.getItem('token')) {
                localStorage.removeItem('token');
            }
            navigate('/login');

        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        checkAuthentication();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Dashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <Sidebar activePage={'myorders'} />

                <div className="home-container">
                    <div className="container pt-4">
                        <div className="row">
                            <div class="col-lg-12 table-column">
                                <h2>Order Details</h2>
                                <h5>Service : {order.order_name}</h5>
                                <h5>Payment ID : {order.payment_id}</h5>
                                <h5>GST Number : {order.gst_number}</h5>
                                <p>Category : {order.category}<br></br>Description : <br></br>{order.details}</p>
                                <p>Documents : <br></br><ul>{order.documents.map((doc) => { return (<><li>{doc.doc_name}</li> </>); })}</ul></p>
                                <p>Timeline : {order.sla}</p>
                                <p>Order Price : <strong>Rs.{order.price}/-</strong></p>
                                <p>Order Date : {order.date}</p>
                                <p>Order Status : {order.status}</p>
                                <Link to={`/orderprogress/${order.id}`}><button class="btn btn-primary">Order Progress</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default OrderDetail