import React, { useState, useEffect } from 'react';
import axios from 'axios';
import iziToast from 'izitoast';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../AuthContext';
const Forgetpassword = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  localStorage.removeItem('userData');
  const token = localStorage.getItem('token');
  const [timer, settimer] = useState(0);

  
  useEffect(() => {
    document.getElementById('otpfield').style.display = 'none';
    document.getElementById('resetform').style.display = 'none';
    document.getElementById('verifybtn').style.display = 'none';
  }, []);

  const [userData, setUserData] = useState({
    email: '',
    otp: '',
    password: '',
    password_confirmation: '',
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleOTP = (e) => {
    axios.post(apipath + 'forgetpassword', userData)
      .then((response) => {
        var data = response.data;
        console.log(data);
        if (data.status === "false") {
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
        else if (data.status === "true") {
          iziToast.success({
            message: data.message,
            position: "topCenter"
          });
          settimer(120);
          document.getElementById('timer').style.display = 'block';
          document.getElementById('otpfield').style.display = 'block';
          document.getElementById('verifybtn').style.display = 'block';
          document.getElementById('otpbtn').style.display = 'none';
          document.getElementById('otpbtn').innerHTML = 'Resend OTP';
        }
      })
      .catch((error) => {
        iziToast.error({
          message: error,
          position: "topCenter"
        });
      });
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        settimer(timer - 1);
      }, 1000);
    }
    if (timer === 0) {
      document.getElementById('otpbtn').style.display = 'block';
      document.getElementById('timer').style.display = 'none';
    }
  }, [timer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(apipath + 'verifyOtp', userData)
      .then((response) => {
        var data = response.data;
        console.log(data);
        if (data.status === "false") {
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
        else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
            document.getElementById('verifyform').style.display = 'none';
            document.getElementById('resetform').style.display = 'block';
        }
      })
      .catch((error) => {
        iziToast.error({
          message: error,
          position: "topCenter"
        });
      });
  };
  
  const handlePassword = (e) => {
    e.preventDefault();
    axios.post(apipath + 'changepassword', userData)
      .then((response) => {
        var data = response.data;
        console.log(data);
        if (data.status === "false") {
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
        else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
            navigate('/login');
        }
      })
      .catch((error) => {
        iziToast.error({
          message: error,
          position: "topCenter"
        });
      });
  };


  return (
    <>
      <div className="container-fluid register-container">
        <div className="row main-register-row">
          <div className="col-lg-4">
            <div className="register-box-left">
              <img src="images/reg-icon.png" alt="" />
            </div>
          </div>
          <div className="col-lg-8 register-column">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="register-box-right">
                  <h4>Forget Password</h4>
                  <h6 className="pt-3 pb-4">
                    {" "}
                    Welcome to <span>TaxAJ</span>{" "}
                  </h6>

                  <form className="register-form" id='verifyform' onSubmit={handleSubmit}>
                    <div className="mb-5">
                      <input type="email" name='email' required onChange={handleChange} className="form-control" placeholder="Email Address" />
                    </div>
                    <div className="mb-1" id='otpfield'>
                      <input type="text" name='otp' required onChange={handleChange} className="form-control" placeholder="Enter OTP" />
                      <span toggle="#password-field" className="bi bi-eye-fill field-icon toggle-password"></span>
                    </div>
                    <div className='mb-3'>
                      <button type='button' className='float-right btn btn-sm btn-primary shadow-none' id='otpbtn' onClick={handleOTP}>Send OTP</button>
                      <button type='button' className='float-right btn btn-sm btn-primary shadow-none' id='timer'>{timer}</button>
                    </div>
                    <button id='verifybtn' class="register-btn mb-4">
                      <span>Verify</span>
                    </button>
                    <small><Link to="/login" >Login</Link> </small>
                  </form>

                  <form className="register-form" id='resetform' onSubmit={handlePassword}>
                    <div className="mb-5">
                      <input type="text" name='password' required onChange={handleChange} className="form-control" placeholder="Password" />
                    </div>
                    <div className="mb-5">
                      <input type="text" name='password_confirmation' required onChange={handleChange} className="form-control" placeholder="Confirm Password" />
                    </div>
                    <button id='resetbtn' class="register-btn mb-4">
                      <span>Reset Password</span>
                    </button>
                    <small><Link to="/login" >Login</Link> </small>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Forgetpassword