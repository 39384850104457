import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Staffnavbar from "./Staffnavbar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Staffticket = () => {
  const { apipath, staffLogout } = useAuth();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const stafftoken = localStorage.getItem('stafftoken');
  const [loading, setLoading] = useState(true);
  const [ticketData, setticketData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState('');

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilters(value);
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...ticketData];
    const filterValue = filters.toLowerCase(); if (filterValue) {
      filteredItems = filteredItems.filter(item => {
        return Object.values(item).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(filterValue)
        );
      });
    }
    setFilteredData(filteredItems);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'stafftickets',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + stafftoken
            }
          }
        );
        setticketData(response.data.tickets);
        setFilteredData(response.data.tickets);
        if (response.data.status === "false") {
          localStorage.removeItem('stafftoken');
          navigate('/stafflogin');
        }
      }
      catch (error) {
        console.error('Error fetching orders data:', error);
        if (localStorage.getItem('stafftoken')) {
          localStorage.removeItem('stafftoken');
        }
        navigate('/stafflogin');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);

  const [statusData, setstatusData] = useState({
    status: '',
    ticket_id: '',
  });
  const handleStatus = (e) => {
    var oid = e.target.getAttribute('data-ticketid');
    setstatusData({ ...statusData, ['status']: e.target.value, ['ticket_id']: oid });
  }
  useEffect(() => {
    if (statusData.status !== '') {
      axios.post(apipath + 'update_ticket_status', statusData,
        {
          headers: {
            Authorization: 'Bearer ' + stafftoken
          }
        })
        .then((response) => {
          var data = response.data;
          if (data.status === "false") {
            iziToast.error({
              message: data.message,
              position: "topCenter"
            });
          }
          else if (data.status === "true") {
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
          }
        })
        .catch((error) => {
          iziToast.error({
            message: error,
            position: "topCenter"
          });
        });
    }
  }, [statusData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const scolumns = [
    { name: 'Ticket ID', selector: row => row.ticket_id, sortable: true, width: '100px' },
    { name: 'Name', selector: row => row.user_name, sortable: true, width: '150px' },
    { name: 'Email', selector: row => row.user_email, sortable: true, width: '150px' },
    { name: 'Department', selector: row => row.department, sortable: true, width: '150px' },
    { name: 'Subject', selector: row => row.subject, sortable: true, width: '200px' },
    { name: 'Related Service', selector: row => row.service_name, sortable: true, width: '200px' },
    { name: 'Date', selector: row => row.date, sortable: true, width: '100px' },
    {
      name: 'Status', selector: row => (
        <>
          <select class="status_select" onChange={handleStatus} data-ticketid={row.id}>
            <option selected={row.status === "Pending"} value={'Pending'}>Pending</option>
            <option selected={row.status === "Open"} value={'Open'}>Open</option>
            <option selected={row.status === "Closed"} value={'Closed'}>Closed</option>
          </select>
        </>
      ), sortable: true, width: '200px'
    },
    {
      name: 'Action', cell: row => (
        <Link to={`/staffticketview/${row.id}`}><i class="bi bi-eye viewbtn"></i></Link>
      )
    },
    // Add more columns as needed
  ];

  // if (ticketData.tickets.length > 0) {
  //   var rowdata = ticketData.tickets;
  //   var rows = rowdata.map((ticket) => {

  //     var selstatus = <>
  //       <option selected={ticket.status === "Open"} value={'Open'}>Open</option>
  //       <option selected={ticket.status === "Closed"} value={'Closed'}>Closed</option>
  //     </>
  //     return (
  //       <>
  //         <tr>
  //           <td>{ticket.ticket_id}</td>
  //           <td>{(ticket.user_name.length > 25) ? ticket.user_name.substr(0, 25) + '...' : ticket.user_name}</td>
  //           <td>{(ticket.user_email.length > 25) ? ticket.user_email.substr(0, 25) + '...' : ticket.user_email}</td>
  //           <td>{ticket.department}</td>
  //           <td>{ticket.assigned_to}</td>
  //           <td>{(ticket.subject.length > 25) ? ticket.subject.substr(0, 25) + '...' : ticket.subject}</td>
  //           <td>{(ticket.service_name.length > 25) ? ticket.service_name.substr(0, 25) + '...' : ticket.service_name}</td>
  //           <td>
  //             <select class="status_select" onChange={handleStatus} data-ticketid={ticket.id}>
  //               <option selected={ticket.status === "Pending"} value={'Pending'}>Pending</option>
  //               {selstatus}
  //             </select>
  //           </td>
  //           <td>{ticket.date}</td>
  //           <td>
  //             <Link to={`/staffticketview/${ticket.id}`}><i class="bi bi-eye viewbtn"></i></Link> &nbsp;
  //             {/* <Link to={`/orderprogress/${ticket.id}`}><button >Progress</button></Link> */}
  //             {/* <i class="bi bi-trash3"></i> &nbsp; */}
  //             {/* <i class="bi bi-download"></i> &nbsp; */}
  //           </td>
  //         </tr>
  //       </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No Tickets.";
  // }
  return (
    <>
      <Staffnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show1" : null}`}>
          <nav className="nav1">
            <div>
              <div className="nav-list">
                <Link
                  to={`/staffcustomer`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">Customers</span>
                </Link>
                <Link
                  to={`/staffchat`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-chat-left-text"></i>
                  <span className="nav-link-name">Chats</span>
                </Link>
                <Link
                  to={`/staffticket active-bar`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-ticket-detailed"></i>
                  <span className="nav-link-name">Tickets</span>
                </Link>
                <Link
                  to={`/stafftask`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-hdd-rack"></i>
                  <span className="nav-link-name">Tasks</span>
                </Link>
                <hr />
                {/* <Link
                  to={``}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Profile</span>
                </Link> */}
                <Link onClick={staffLogout}
                  to={`/customer-profile/`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">
          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>Tickets</h5>
                <div>
                  <div className="row m-0" id="table_detail">
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Search"
                        value={filters.order_name}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-2 p-1">
                      <button onClick={applyFilters}>Search</button>
                    </div>
                  </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>Ticket ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Department</th>
                      <th>Assigned to</th>
                      <th>Subject</th>
                      <th>Related Service</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                    {rows}
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Staffticket