import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
const Admineditcustomer = () => {
    const { apipath, adminLogout } = useAuth();
    const [show, setShow] = useState(true);
    const { id } = useParams();
    console.log(id);
    const navigate = useNavigate();
    const admintoken = localStorage.getItem('admintoken');
    const sdata = localStorage.getItem('customerData');
    const staffdata = JSON.parse(sdata);
    console.log(staffdata);
    var staff = {
        id : "",
        name : "",
        phone : "",
        password : "",
        user_type : "user",
    }
    for (let i=0; i < staffdata.length; i++) {
        if (staffdata[i].id == id) {
            staff = staffdata[i];
            console.log(staff);
        }
    }
    const [userData, setUserData] = useState({
        id: staff.id,
        name: staff.name,
        email: staff.email,
        phone: staff.phone,
        password: staff.password,
        user_type: "user",
    });
    
    const checkstaff = async () => {
        if(staff.id === ""){
            iziToast.error({
                message: "Customer not found.",
                position: "topCenter"
            });
            navigate('/admincustomers');
        }
    }
    useEffect(() => {
        checkstaff();
        document.getElementById('sname').value = staff.name;
        document.getElementById('semail').value = staff.email;
        document.getElementById('sphone').value = staff.phone;
        document.getElementById('password').value = staff.password;
    }, []);

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(apipath+'staffedit', userData,
          {
            headers: {
              Authorization: 'Bearer ' + admintoken
            }
          })
            .then((response) => {
                var data = response.data;
                if (data.status === "false") {
                    iziToast.error({
                        message: data.message,
                        position: "topCenter"
                    });
                }
                else if (data.status === "true") {
                    iziToast.success({
                        message: data.message,
                        position: "topCenter"
                    });
                    navigate('/admincustomers');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    var viewPassword = () => {
        var password = document.getElementById('password');
        if(password.getAttribute('type') === "password"){
            password.setAttribute('type','text');
        }
        else{
            password.setAttribute('type','password');
        }
    }
    return (
        <>
            <Admindashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <aside className={`sidebar ${show ? "show1" : null}`}>
                    <nav className="nav1">
                        <div>
                            <div className="nav-list">
                                <Link
                                    to={`/admindashboard`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-grid-1x2"></i>
                                    <span className="nav-link-name">Dashboard</span>
                                </Link>
                                <Link
                                    to={`/admincustomers`}
                                    className="nav-linksidebar active-bar"
                                >
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                    <span className="nav-link-name">Customers</span>
                                </Link>
                                <Link
                                    to={`/adminstaff`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Staff</span>
                                </Link>
                                <Link
                                    to={`/adminorders`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Orders</span>
                                </Link>
                                <Link
                                    to={`/adminchat`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-chat-left-text"></i>
                                    <span className="nav-link-name">Chats</span>
                                </Link>
                                <Link
                                    to={`/adminticket`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-ticket-detailed"></i>
                                    <span className="nav-link-name">Tickets</span>
                                </Link>
                                <Link
                                    to={`/adminservice`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Services</span>
                                </Link>
                                {/* <Link
                                    to={`/admintask`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Tasks</span>
                                </Link> */}
                                <hr />
                                {/* <Link
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-webcam"></i>
                                    <span className="nav-link-name">Profile</span>
                                </Link> */}
                                <Link onClick={adminLogout}
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-gear"></i>
                                    <span className="nav-link-name">Logout</span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </aside>

                <div className="home-container">

                    <div className="container pt-4">
                        <div className="row">
                            <div class="col-lg-12 table-column">
                                <h5>Edit Customer</h5>
                                <div>

                                    <form className="register-form" onSubmit={handleSubmit}>
                                        <div className="mb-5">
                                            <label>Name</label>
                                            <input type="text" id="sname" name="name" required className="form-control" onChange={handleChange} placeholder="Full Name" />
                                        </div>
                                        <div className="mb-5">
                                            <label>Email</label>
                                            <input type="email" id="semail" name="email" required className="form-control" onChange={handleChange} placeholder="Email Address" />
                                        </div>
                                        <div className="mb-5">
                                            <label>Phone</label>
                                            <input type="text" id="sphone" name="phone" required className="form-control" onChange={handleChange} placeholder="Phone Number" />
                                        </div>
                                        <div className="mb-5">
                                            <label>Password</label>
                                            <input type="password" id="password" name="password" required className="form-control" onChange={handleChange} placeholder="Password" />
                                            <span toggle="#password-field" className="bi bi-eye-fill field-icon toggle-password" onClick={viewPassword}></span>
                                        </div>
                                        <button class="register-btn mb-4">
                                            <span>Update Customer</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Admineditcustomer