import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Dashnavbar from "./Dashnavbar";
import Sidebar from "./Sidebar";
import { useAuth } from '../AuthContext';
import DataTable from 'react-data-table-component';
const Myorders = () => {
  const [show, setShow] = useState(true);
  // const { customerId } = useParams();
  const { logout, apipath } = useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [myorderData, setmyorderData] = useState([]);
  const thanks = localStorage.getItem('thankyou');
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({ order_name: '', date: '' });
  const today = new Date().toISOString().split('T')[0];
  if (thanks !== null) {
    iziToast.success({
      message: "Your order of amount Rs." + thanks + " is placed successfully.<br>Thank You!",
      position: "topCenter"
    });
    localStorage.removeItem('thankyou');
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };
  const applyFilters = () => {
    console.log(filters);
    let filteredItems = [...myorderData];
    Object.keys(filters).forEach(key => {
      const filterValue = filters[key].toLowerCase();
      if (filterValue) {
        filteredItems = filteredItems.filter(item =>
          item[key].toLowerCase().includes(filterValue)
        );
      }
    });
    setFilteredData(filteredItems);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          apipath + 'orders',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        );
        setmyorderData(response.data.orders);
        setFilteredData(response.data.orders);
        if (response.data.status === "false") {
          localStorage.removeItem('token');
          navigate('/login');
        }
      }
      catch (error) {
        console.error('Error fetching orders data:', error);
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
        navigate('/login');

      } finally {
        setLoading(false);
      }
    }
    checkAuthentication();
  }, []);
  console.log(myorderData);


  if (loading) {
    return <div>Loading...</div>;
  }
  localStorage.setItem('myorderData', JSON.stringify(myorderData));
  
  const scolumns = [
    { name: 'Order ID', selector: row => row.order_id, sortable: true, width: '130px' },
    { name: 'Order Name', selector: row => row.order_name, sortable: true, width: '250px' },
    { name: 'Order Status', selector: row => row.status, sortable: true },
    { name: 'Order Date (yyyy-mm-dd)', selector: row => row.date, sortable: true },
    { name: 'Amount', selector: row => row.price, sortable: true },
    // { name: 'Documents Upload', selector: row => "Pending", sortable: true },
    { name: 'Payment Status', selector: row => row.payment_status, sortable: true },
    {
      name: 'Action', cell: row => (
        <>
        <Link to={`/orderdetail/${row.id}`}><i class="bi bi-eye viewbtn"></i></Link>&nbsp;
        <Link to={`/orderprogress/${row.id}`}><button class="progressbtn">Progress</button></Link>
        </>
      )
    },
    // Add more columns as needed
  ];
  // if (myorderData.orders.length > 0) {
  //   var rowdata = myorderData.orders;
  //   var rows = rowdata.map((ordr) => {
  //     return (
  //       <>
  //         <tr>
  //           <td>{ordr.order_id}</td>
  //           <td>{(ordr.order_name.length > 50) ? ordr.order_name.substr(0, 50) + '...' : ordr.order_name.substr(0, 50)}</td>
  //           <td>{ordr.status}</td>
  //           <td>{ordr.date}</td>
  //           <td>Rs.{ordr.price}</td>
  //           <td>Pending</td>
  //           <td>{ordr.payment_status}</td>
  //           <td>
  //             <Link to={`/orderdetail/${ordr.id}`}><i class="bi bi-eye viewbtn"></i></Link> &nbsp;
  //             <Link to={`/orderprogress/${ordr.id}`}><button >Progress</button></Link>
  //             {/* <i class="bi bi-trash3"></i> &nbsp; */}
  //             {/* <i class="bi bi-download"></i> &nbsp; */}
  //           </td>
  //         </tr>
  //       </>
  //     );
  //   });
  // }
  // else {
  //   rows = "No orders.";
  // }
  return (
    <>
      <Dashnavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <Sidebar activePage={window.location.pathname.split('/')[1]} />

        <div className="home-container">
          <div className="container pt-4">
            <div className="row">
              <div class="col-lg-12 table-column">
                <h5>My Orders</h5>
                <div>
                  <div className="row m-0" id="table_detail">
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="text"
                        name="order_name"
                        placeholder="Search by name"
                        value={filters.order_name}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 p-1">
                      <input
                        className="form-control"
                        type="date"
                        name="date"
                        placeholder="Search by Date"
                        value={filters.date}
                        max={today}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 mb-2 p-1">
                      <button onClick={applyFilters}>Search</button>
                    </div>
                  </div>
                  <DataTable
                    columns={scolumns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10,25,50,100]}
                  />
                  {/* <table id="table_detail">
                    <tr>
                      <th>Order ID</th>
                      <th>Order Name</th>
                      <th>Order Status</th>
                      <th>Order Date</th>
                      <th>Amount</th>
                      <th>Documents Upload</th>
                      <th>Payment Status</th>
                      <th>Action</th>
                    </tr>
                    {rows}
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Myorders