import React, {useState} from "react";
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const Sidebar = ({ activePage }) => {
    const [show, setShow] = useState(true);
    const { logout } = useAuth();

    return (
        <>
            <aside className={`sidebar ${show ? "show1" : null}`}>
                <nav className="nav1">
                    <div>
                        <div className="nav-list">
                            <Link
                                to={`/dashboard`}
                                className={activePage === 'dashboard' ? 'nav-linksidebar active-bar' : 'nav-linksidebar'}
                            >
                                <i class="bi bi-grid-1x2"></i>
                                <span className="nav-link-name">Dashboard</span>
                            </Link>
                            {/* <Link
                  to={`/mydocuments`}
                  className="nav-linksidebar"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                  <span className="nav-link-name">My Documents</span>
                </Link> */}
                            <Link
                                to={`/myorders`}
                                className={activePage === 'myorders' ? 'nav-linksidebar active-bar' : 'nav-linksidebar'}
                            >
                                <i class="bi bi-box-seam"></i>
                                <span className="nav-link-name">My Orders</span>
                            </Link>
                            <Link
                                to={`/chat`}
                                className={activePage === 'chat' ? 'nav-linksidebar active-bar' : 'nav-linksidebar'}
                            >
                                <i class="bi bi-chat-left-text"></i>
                                <span className="nav-link-name">Chats</span>
                            </Link>
                            <Link
                                to={`/raiseticket`}
                                className={activePage === 'raiseticket' ? 'nav-linksidebar active-bar' : 'nav-linksidebar'}
                            >
                                <i class="bi bi-ticket-detailed"></i>
                                <span className="nav-link-name">Raise Ticket</span>
                            </Link>
                            <Link
                                to={`/services`}
                                className={activePage === 'services' ? 'nav-linksidebar active-bar' : 'nav-linksidebar'}
                            >
                                <i class="bi bi-hdd-rack"></i>
                                <span className="nav-link-name">Services</span>
                            </Link>
                            <hr />
                            {/* <Link
                                to={``}
                                className={activePage === 'profile' ? 'nav-linksidebar active-bar' : 'nav-linksidebar'}
                            >
                                <i class="bi bi-webcam"></i>
                                <span className="nav-link-name">Profile</span>
                            </Link> */}
                            <Link onClick={logout}
                                to={''}
                                className="nav-linksidebar"
                            >
                                <i class="bi bi-gear"></i>
                                <span className="nav-link-name">Logout</span>
                            </Link>
                        </div>
                    </div>
                </nav>
            </aside>
        </>
    )
}

export default Sidebar