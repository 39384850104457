import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import Admindashnavbar from "./Admindashnavbar";
import { useAuth } from '../AuthContext';
const Adminaddstaff = () => {
    const { apipath, adminLogout } = useAuth();
    const [show, setShow] = useState(true);
    const navigate = useNavigate();
    const admintoken = localStorage.getItem('admintoken');
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        phone: '',
        department: '',
        password: '',
    });

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(apipath+'staffregister', userData,
          {
            headers: {
              Authorization: 'Bearer ' + admintoken
            }
          })
            .then((response) => {
                var data = response.data;
                if (data.status === "false") {
                    iziToast.error({
                        message: data.message,
                        position: "topCenter"
                    });
                }
                else if (data.status === "true") {
                    iziToast.success({
                        message: data.message,
                        position: "topCenter"
                    });
                    navigate('/adminstaff');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    var viewPassword = () => {
        var password = document.getElementById('password');
        if(password.getAttribute('type') === "password"){
            password.setAttribute('type','text');
        }
        else{
            password.setAttribute('type','password');
        }
    }
    return (
        <>
            <Admindashnavbar />
            <main className={show ? "space-toggle" : null}>
                <header className={`header ${show ? "space-toggle" : null}`}>
                    <div className="header-toggle" onClick={() => setShow(!show)}>
                        <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
                    </div>
                </header>

                <aside className={`sidebar ${show ? "show1" : null}`}>
                    <nav className="nav1">
                        <div>
                            <div className="nav-list">
                                <Link
                                    to={`/admindashboard`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-grid-1x2"></i>
                                    <span className="nav-link-name">Dashboard</span>
                                </Link>
                                <Link
                                    to={`/admincustomers`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                    <span className="nav-link-name">Customers</span>
                                </Link>
                                <Link
                                    to={`/adminstaff`}
                                    className="nav-linksidebar active-bar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Staff</span>
                                </Link>
                                <Link
                                    to={`/adminorders`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-box-seam"></i>
                                    <span className="nav-link-name">Orders</span>
                                </Link>
                                <Link
                                    to={`/adminchat`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-chat-left-text"></i>
                                    <span className="nav-link-name">Chats</span>
                                </Link>
                                <Link
                                    to={`/adminticket`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-ticket-detailed"></i>
                                    <span className="nav-link-name">Tickets</span>
                                </Link>
                                <Link
                                    to={`/adminservice`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Services</span>
                                </Link>
                                {/* <Link
                                    to={`/admintask`}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-hdd-rack"></i>
                                    <span className="nav-link-name">Tasks</span>
                                </Link> */}
                                <hr />
                                {/* <Link
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-webcam"></i>
                                    <span className="nav-link-name">Profile</span>
                                </Link> */}
                                <Link onClick={adminLogout}
                                    to={``}
                                    className="nav-linksidebar"
                                >
                                    <i class="bi bi-gear"></i>
                                    <span className="nav-link-name">Logout</span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </aside>

                <div className="home-container">

                    <div className="container pt-4">
                        <div className="row">
                            <div class="col-lg-12 table-column">
                                <h5>Add Staff</h5>
                                <div>

                                    <form className="register-form" onSubmit={handleSubmit}>
                                        <div className="mb-5">
                                            <input type="text" name="name" required className="form-control" onChange={handleChange} placeholder="Full Name" />
                                        </div>
                                        <div className="mb-5">
                                            <input type="email" name="email" required className="form-control" onChange={handleChange} placeholder="Email Address" />
                                        </div>
                                        <div className="mb-5">
                                            <input type="text" name="phone" required className="form-control" onChange={handleChange} placeholder="Phone Number" />
                                        </div>
                                        <div className="mb-5">
                                            <select name="department" required className="form-control" onChange={handleChange}>
                                                <option value="" >Select Department</option>
                                                <option value="Start Up" >Start Up</option>
                                                <option value="Income Tax" >Income Tax</option>
                                                <option value="All in One" >All in One</option>
                                                <option value="GST Returns" >GST Returns</option>
                                                <option value="Payroll Services" >Payroll Services</option>
                                                <option value="Secretarial Filings" >Secretarial Filings</option>
                                                <option value="TDS Returns" >TDS Returns</option>
                                                <option value="Licenses/Regulatory Registrations" >Licenses/Regulatory Registrations</option>
                                                <option value="Legal Drafting/Filings" >Legal Drafting/Filings</option>
                                                <option value="Intellectual Property Rights" >Intellectual Property Rights</option>
                                                <option value="Conversion" >Conversion</option>
                                                <option value="Closure" >Closure</option>
                                            </select>
                                        </div>
                                        <div className="mb-5">
                                            <input type="password" name="password" id="password" required className="form-control" onChange={handleChange} placeholder="Password" />
                                            <span toggle="#password-field" className="bi bi-eye-fill field-icon toggle-password" onClick={viewPassword}></span>
                                        </div>
                                        <button class="register-btn mb-4">
                                            <span>Add Staff</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Adminaddstaff