import React, { useState, useEffect } from 'react';
import axios from 'axios';
import iziToast from 'izitoast';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../AuthContext';
const Login = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  localStorage.removeItem('userData');
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token != null) {
      navigate('/dashboard');
    }
  });

  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(apipath+'login', userData)
      .then((response) => {
        var data = response.data;
        console.log(data);
        if (data.status === "false") {
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
        else if (data.status === "true") {
          if(data.user_type === "user"){
            localStorage.setItem('token',data.token);
            localStorage.setItem('userid',data.user_id);
            iziToast.success({
              message: data.message,
              position: "topCenter"
            });
            navigate('/dashboard');
          }
          else{
            iziToast.error({
              message: "Invalid Credentials",
              position: "topCenter"
            });
          }
        }
      })
      .catch((error) => {
        iziToast.error({
          message: error,
          position: "topCenter"
        });
      });
  };
  
  var viewPassword = () => {
    var password = document.getElementById('password');
    if(password.getAttribute('type') === "password"){
        password.setAttribute('type','text');
    }
    else{
        password.setAttribute('type','password');
    }
}


  return (
    <>
      <div className="container-fluid register-container">
        <div className="row main-register-row">
          <div className="col-lg-4">
            <div className="register-box-left">
              <img src="images/reg-icon.png" alt="" />
            </div>
          </div>
          <div className="col-lg-8 register-column">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="register-box-right">
                  <h4>Login to your Account</h4>
                  <h6 className="pt-3 pb-4">
                    {" "}
                    Welcome to <span>TaxAJ</span>{" "}
                  </h6>

                  <form className="register-form" onSubmit={handleSubmit}>
                    <div className="mb-5">
                      <input type="email" name='email' required onChange={handleChange} className="form-control" placeholder="Email Address" />
                    </div>
                    <div className="mb-5">
                      <input type="password" name='password' id='password' required onChange={handleChange} className="form-control" placeholder="Password" />
                      <span toggle="#password-field" className="bi bi-eye-fill field-icon toggle-password" onClick={viewPassword}></span>
                    </div>
                    <small><Link to="/forgetpassword" >Forget Password?</Link> </small>
                    <button class="register-btn mb-4">
                      <span>Login</span>
                    </button>
                    <small>Don't have an account? <Link to="/register" >Create One</Link> </small>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login